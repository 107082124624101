import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'incrementNumber'})

export class IncrementNumber implements PipeTransform {
  transform(value, args:string[]) : any {
    let res = [];
    for (let i = 50; i < value; i++) {
        res.push(i);
      }
      return res;
  }
}