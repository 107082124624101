import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl  } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-employee-vehicle',
  templateUrl: './employee-vehicle.component.html',
  styleUrls: ['./employee-vehicle.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class EmployeeVehicleComponent implements OnInit {

  allData =  [
    {license: "B.1", data_end: { "year": 2024, "month": 3, "day": 7 }, vehicle: ["Camión", "Auto", "Motocicleta"], notification_users: [], notification_previous_alert: "", notification_status: "false"},
    {license: "A.1.2", data_end: { "year": 2024, "month": 3, "day": 7 }, vehicle: ["Auto", "Motocicleta"], notification_users: ["jjaime@gmail.com","agdsa@gmail.com"], notification_previous_alert: 3, notification_status: "true"},
    {license: "A.1.1", data_end: { "year": 2024, "month": 3, "day": 7 }, vehicle: ["Camión", "Auto"], notification_users: ["emma@gmail.com","joaco@gmail.com", "jjaime@gmail.com"], notification_previous_alert: 3, notification_status: "false"},
    {license: "A.1.1", data_end: null, vehicle: ["Camión", "Auto"], notification_users: [], notification_previous_alert: "", notification_status: "false"},
  ];

  items = [
    {id: 1, name: 'Jonatan J', email: 'jjaime@gmail.com'},
    {id: 2, name: 'Emmanuel P', email: 'emma@gmail.com'},
    {id: 3, name: 'Joaquin B', email: 'joaco@gmail.com'},
    {id: 4, name: 'Edgar R', email: 'agdsa@gmail.com'},
  ];

  data: FormGroup;
  enableTitle = false;
  submitted = false;
  currentDate = new Date();
  files: File[] = [];

  // Modal Alert
  private modalRefAlert;
  data_alert: FormGroup;

  // Modal Licenses
  private modalRefLicense;
  selectedLicense = null;
  data_licenses: FormGroup;
  submitLicense: string;
  submitLicenseEditValue: string;

  // https://www.argentina.gob.ar/seguridadvial/licencianacional/clasesysubclases
  items_license : any[] = [
    { name: 'Agregar nuevo', disabled: true },
    { name: 'A.1.1'},
    { name: 'A.1.2'},
    { name: 'A.1.3'},
    { name: 'A.1.4'},
    { name: 'A.2.1'},
    { name: 'A.2.2'},
    { name: 'A.3'},
    { name: 'B.1'},
    { name: 'B.2'},
    { name: 'C.1'},
    { name: 'C.2'},
    { name: 'C.3'},
    { name: 'D.1'},
    { name: 'D.2'},
    { name: 'D.3'},
    { name: 'D.4'},
    { name: 'E.1'},
    { name: 'E.2'},
    { name: 'F'},
    { name: 'G.1'},
    { name: 'G.2'},
    { name: 'G.3'},
  ];
  // end Modal Licenses



  // Modal Vehicle
  private modalRefVehicle;
  selectedVehicle = null;
  data_vehicles: FormGroup;
  submitVehicle: string;
  submitVehicleEditValue: string;

  items_vehicle : any[] = [
    { name: 'Agregar nuevo', disabled: true },
    { name: 'Auto' },
    { name: 'Motocicleta' },
    { name: 'Camión' },
    { name: 'Camioneta' },
    { name: 'Cuatriciclo' },
    { name: 'Triciclo' },
    { name: 'Ciclomotor' },
  ];
  // end Modal Vehicle

  // DISABLED ON EDIT
  disabled_on_edit:boolean = true;
  
  constructor(private fb: FormBuilder, private modalService: NgbModal, private cdRef:ChangeDetectorRef){
    for(let i=1;i<=10;i++){
      this.allData.push();
    }
  }
  
  ngOnInit() {
    this.initForm();
    this.initFormLicense();
    this.initFormVehicle();
    this.initFormAlert();
  }

  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  initForm() {
    this.data = this.fb.group({
      license:[null, Validators.required],
      vehicle:[null, Validators.required],
      data_end:[null, Validators.required],
      description:['']
    });
  }

   // Modal EDIT and NEW
  openModalEdit(targetModal, data) {

    this.disabled_on_edit = true;

    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
   
    this.data.patchValue({
      license: data.license,
      vehicle: data.vehicle,
      description: data.description,
      data_end: data.data_end
    });
  }

  openModalNew(targetModal) {

    this.disabled_on_edit = false;

    this.initForm();
    this.cdRef.detectChanges();

    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  get f() { return this.data.controls; }
   
  onSubmit() {

    this.submitted = true;

    if (this.data.invalid) {
        return;
    }

    Swal.fire(
      '¡Éxito!',
      'Tus cambios se guardaron correctamente',
      'success'
    )
    this.modalService.dismissAll();
    console.log("res:", this.data.getRawValue());
    
  }

  //  DELETE 
  delete() {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          '¡Éxito!',
          'Eliminado correctamente',
          'success'
        )
      }
    })
  }

  //Files Dropzone
  onSelect(event) {
    if (this.files.length < 2) {
      console.log(event);
      this.files.push(...event.addedFiles);
    }
  }

  deleteFile(event) {
    Swal.fire({
      title: '¿Está seguro que quiere eliminar este archivo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar'
    }).then((result) => {
      if (result.value) {
        this.files.splice(this.files.indexOf(event), 1);
        Swal.fire(
          '¡Éxito!',
          'Archivo eliminado',
          'success'
        )
      }
    })
  }



  /// FORM LICENSE
  openModalLicenseEdit(targetModal, license) {
    this.modalRefLicense = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
   
    this.submitLicense = 'edit';
    this.submitLicenseEditValue = license.name;

    this.data_licenses.patchValue({
      name: license.name,
    });
  }

  initFormLicense() {
    this.data_licenses = this.fb.group({
      name:['', Validators.required],
    });
  }

  openModalLicenseNew(targetModal) {
    this.initFormLicense();
    this.cdRef.detectChanges();

    this.submitLicense = 'new';

    this.modalRefLicense = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  modalDismissLicense() {
    this.modalRefLicense.close();  
  }

  get fn() { return this.data_licenses.controls; }
   
  onSubmitLicense() {

    this.submitted = true;

    if (this.data_licenses.invalid) {
        return;
    }

    if(this.submitLicense == 'edit') {
      this.items_license.find(item => item.name == this.submitLicenseEditValue).name =  this.data_licenses.value.name;
      this.items_license = [...this.items_license];
      this.data.get('license').setValue(this.data_licenses.value.name)
    }

    if(this.submitLicense == 'new') {
      this.items_license = [...this.items_license, this.data_licenses.getRawValue()];
      this.data.get('license').setValue(this.data_licenses.value.name)
    }

    this.modalRefLicense.close();
    this.cdRef.detectChanges();
  }

  deleteLicense(index) {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {

        this.items_license.splice(index,1);
        this.items_license = [...this.items_license];

        this.selectedLicense = [];

        this.cdRef.detectChanges();
        
        console.log(this.items_license);

        Swal.fire(
          '¡Éxito!',
          'Eliminado correctamente',
          'success'
        )
      }
    })
  }

  /// END FORM LICENSE







   /// FORM VEHICLE
   openModalVehicleEdit(targetModal, vehicle) {
    this.modalRefVehicle = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
   
    this.submitVehicle = 'edit';
    this.submitVehicleEditValue = vehicle.name;

    this.data_vehicles.patchValue({
      name: vehicle.name,
    });
  }

  initFormVehicle() {
    this.data_vehicles = this.fb.group({
      name:['', Validators.required],
    });
  }

  openModalVehicleNew(targetModal) {
    this.initFormVehicle();
    this.cdRef.detectChanges();

    this.submitVehicle = 'new';

    this.modalRefVehicle = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  modalDismissVehicle() {
    this.modalRefVehicle.close();  
  }

  get fnv() { return this.data_vehicles.controls; }
   
  onSubmitVehicle() {

    this.submitted = true;

    if (this.data_vehicles.invalid) {
        return;
    }

    if(this.submitVehicle == 'edit') {
      this.items_vehicle.find(item => item.name == this.submitVehicleEditValue).name =  this.data_vehicles.value.name;
      this.items_vehicle = [...this.items_vehicle];
      this.data.get('vehicle').setValue([...this.data.get('vehicle').value, this.data_vehicles.value.name]);
    }

    if(this.submitVehicle == 'new') {
      this.items_vehicle = [...this.items_vehicle, this.data_vehicles.getRawValue()];

      if(this.data.get('vehicle').value){
        this.data.get('vehicle').setValue([...this.data.get('vehicle').value, this.data_vehicles.value.name]);
      }else {
        this.data.get('vehicle').setValue([this.data_vehicles.value.name]);
      }

    }

    this.modalRefVehicle.close();
    this.cdRef.detectChanges();
  }

  deleteVehicle(index) {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {

        this.items_vehicle.splice(index,1);
        this.items_vehicle = [...this.items_vehicle];

        this.selectedVehicle = [];

        this.cdRef.detectChanges();
        
        console.log(this.items_vehicle);

        Swal.fire(
          '¡Éxito!',
          'Eliminado correctamente',
          'success'
        )
      }
    })
  }

  /// END FORM VEHICLE





  /// FORM ALERT
  openModalAlertEdit(targetModal, data_alert) {
    this.modalRefAlert = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });

    this.data_alert.patchValue({
      notification_users: data_alert.notification_users,
      notification_previous_alert: data_alert.notification_previous_alert,
      notification_status: data_alert.notification_status
    });
  }

  initFormAlert() {
    this.data_alert = this.fb.group({
      notification_users: [null, Validators.required],
      notification_previous_alert: ['', [Validators.required, Validators.min(1)]],
      notification_status: ['']
    });
  }

  modalDismissAlert() {
    this.modalRefAlert.close();  
  }

  get fna() { return this.data_alert.controls; }
   
  onSubmitAlert() {

    this.submitted = true;

    if (this.data_alert.invalid) {
        return;
    }

    console.log("res:", this.data_alert.getRawValue());
    this.modalRefAlert.close();
    this.cdRef.detectChanges();
  }

}