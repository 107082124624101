import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-employee-hr',
  templateUrl: './employee-hr.component.html',
  styleUrls: ['./employee-hr.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class EmployeeHrComponent implements OnInit {
  files: File[] = [];
  data: FormGroup;

  constructor(private fb: FormBuilder, private modalService: NgbModal) { }

  ngOnInit() {
    this.data = this.fb.group({
      reason: [''],
      detail: ['']
    });
   }

  openModalNew(targetModal) {
    this.modalService.open(targetModal, {
     centered: true,
     backdrop: 'static'
    });    
  }
  onSubmit() {
    this.modalService.dismissAll();
    console.log("res:", this.data.getRawValue());
  }



  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }


}
