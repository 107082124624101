import { Component, Renderer2, ViewChild, ElementRef, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-client-objective-areas',
  templateUrl: './client-objective-areas.component.html',
  styleUrls: ['./client-objective-areas.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class ClientObjectiveAreasComponent implements OnInit {
  
  @ViewChild('area', {static:false}) area: ElementRef;

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
  }

  addArea() {
    const area: HTMLDivElement = this.renderer.createElement('area');
    area.innerHTML = `
    <div class="row align-end">
      <div class="col-md-4">
          <div class="wrapper__container__item">
              <span>Título</span>
              <input type="text" name="">
          </div>
      </div>
      <div class="col-md-5">
          <div class="wrapper__container__item">
              <span>Descripción</span>
              <input type="text" name="">
          </div>
      </div>
      <div class="col-md-2">
          <div class="wrapper__container__item">
              <span>Puntos de control</span>
              <input type="number" name="">
          </div>
      </div>
      <div class="col-md-1">
          <button class="btn__small delete" (click)="openModalDelete(modalDelete)"><i class="icon icon-trash-2"></i></button>
      </div>
  </div>
`
    this.renderer.appendChild(this.area.nativeElement, area)
  }


  save() {
    Swal.fire(
      '¡Éxito!',
      'Se ha guardado correctamente',
      'success'
    )
  }
}
