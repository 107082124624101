import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2'
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';
import timeZone from '../../../data_json/time_zone.json';

import { ImageCroppedEvent } from 'ngx-image-cropper';


@Component({
  selector: 'app-company-configuration',
  templateUrl: './company-configuration.component.html',
  styleUrls: ['./company-configuration.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})

export class CompanyConfigurationComponent implements OnInit {
  data: FormGroup;

  color_card_header: string = '#000000';
  color_card_rol: string = '#000000';

  data_timezone = timeZone;
  timezone_final = null;

  currencyData = [
    {id: 1, name: 'USD'},
    {id: 2, name: '$'}
  ];

  objectiveDistanceData = [
    { name: '20 mts' },
    { name: '40 mts' },
    { name: '60 mts' },
    { name: '80 mts' },
    { name: '100 mts'},
    { name: '120 mts'},
    { name: '140 mts'},
    { name: '160 mts'},
    { name: '180 mts'},
    { name: '200 mts'},
    { name: '220 mts'},
    { name: '240 mts'},
    { name: '260 mts'},
    { name: '280 mts'},
    { name: '300 mts'}
  ];
  
  hourData = [
    { name: '0 min' },
    { name: '5 min' },
    { name: '10 min' },
    { name: '15 min' },
    { name: '20 min' },
    { name: '25 min' },
    { name: '30 min' },
    { name: '35 min' },
    { name: '40 min' },
    { name: '45 min' },
    { name: '50 min' },
    { name: '55 min' },
    { name: '60 min' },
  ];

  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageChangedEventHeader: any = '';
  croppedImageHeader: any = '';

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.data = this.fb.group({
      currency: ['$'],
      timezone: ["America, Argentina, Cordoba"],
      color_card_header: [''],
      color_card_rol: [''],
      objective_distance: ['200 mts'],
      life_time: ['30 min'],
      control_start: [true],
      control_end: [true],
      start_daytime: [6],
      start_night_shift: [21],
      income_tolerance: ['15 min'],
      egress_tolerance: ['15 min'],
    });
  }

  get f() { return this.data.controls; }
   
  onSubmit() {
    if (this.data.invalid) {
        return;
    }

    this.data.patchValue({ timezone: this.timezone_final });

    Swal.fire(
      '¡Éxito!',
      'Cambios actualizados correctamente',
      'success'
    )

    if(this.data.getRawValue().timezone) {
      Swal.fire(
          '¡Éxito!',
          'Cambios actualizados correctamente',
          'success'
        ).then((result) => {
          if(result) {
            Swal.fire({
              title: 'Zona horaria actualizada',
              text: "Para aplicar los cambios, debe reiniciar la sesión",
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Continuar'
            }).then((result) => {
              if(result) {
                window.location.reload();
              }
            })
          }
      })
    }

    console.log("res:", this.data.getRawValue());
  }

  onChangeColorHeader(color: string) {
    this.color_card_header = color;
    this.data.patchValue({ color_card_header: color });
  }

  onChangeColorRol(color: string) {
    this.color_card_rol = color;
    this.data.patchValue({ color_card_rol: color });
  }

  save(){
    Swal.fire(
      '¡Éxito!',
      'Tus cambios se guardaron correctamente',
      'success'
    )
  }

  getTimeZone(date, timeZoneId) {
     const final_zone_id = timeZoneId.replaceAll(', ', '/').replaceAll(' ', '_');

      let {hour, minute } = new Intl.DateTimeFormat("es", {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: final_zone_id,
        timeZoneName:'long'
      }).formatToParts(date).reduce((acc, part) => {
        acc[part.type] = part.value;
        return acc;
      }, Object.create(null));

      return `${hour}:${minute}`
  }

  onChange($event) {
    this.timezone_final = $event;
    this.timezone_final.raw_offset_minutes = this.timezone_final.raw_offset * 60;
    // console.log(this.timezone_final);
  }

  fileChangeEvent(event: any): void {
    console.log(event);
      this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  fileChangeEventHeader(event: any): void {
    console.log(event);
      this.imageChangedEventHeader = event;
  }

  imageCroppedHeader(event: ImageCroppedEvent) {
    this.croppedImageHeader = event.base64;
  }

}
