import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-client-objectives',
  templateUrl: './client-objectives.component.html',
  styleUrls: ['./client-objectives.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class ClientObjectivesComponent implements OnInit {


  files: File[] = [];
  openFilter = false;
  submitted = false;
  data: FormGroup;

  status = [
    {name: 'Activo'},
    {name: 'Inactivo'},
    {name: 'Ambos'}
  ];
  city = [
    {name: 'Ítem 1'},
    {name: 'Ítem 2'},
    {name: 'Ítem 3'}
  ];
  province = [
    {name: 'Ítem 1'},
    {name: 'Ítem 2'},
    {name: 'Ítem 3'}
  ];
  staff = [
    {name: 'Ítem 1'},
    {name: 'Ítem 2'},
    {name: 'Ítem 3'}
  ];

  allData = [
    {nombre: "Objetivo 1", localidad: "Venado Tuerto, Santa Fe, Argentina", areas: "1", ctrol: "2", hs: "40", status: true},
    {nombre: "Objetivo 2", localidad: "Villa Cañas, Santa Fe, Argentina", areas: "2", ctrol: "2", hs: "40", status: true},
    {nombre: "Objetivo 3", localidad: "Teodelina, Santa Fe, Argentina", areas: "3", ctrol: "2", hs: "40", status: false},
    {nombre: "Objetivo 4", localidad: "Rufino, Santa Fe, Argentina", areas: "45", ctrol: "2", hs: "40", status: true},
    {nombre: "Objetivo 5", localidad: "Sacti Espiritu, Santa Fe, Argentina", areas: "1", ctrol: "2", hs: "800", status: true},
    {nombre: "Objetivo 6", localidad: "Venado Tuerto, Santa Fe, Argentina", areas: "123", ctrol: "3", hs: "800", status: false},
    {nombre: "Objetivo 7", localidad: "Villa Cañas, Santa Fe, Argentina", areas: "4", ctrol: "3", hs: "800", status: false},
    {nombre: "Objetivo 8", localidad: "Teodelina, Santa Fe, Argentina", areas: "6", ctrol: "3", hs: "200", status: true},
    {nombre: "Objetivo 9", localidad: "Venado Tuerto, Santa Fe, Argentina", areas: "8", ctrol: "5", hs: "200", status: true},
    {nombre: "Objetivo 10", localidad: "Villa Cañas, Santa Fe, Argentina", areas: "6", ctrol: "5", hs: "200", status: true},
    {nombre: "Objetivo 11", localidad: "Teodelina, Santa Fe, Argentina", areas: "9", ctrol: "5", hs: "200", status: true},
    {nombre: "Objetivo 12", localidad: "Venado Tuerto, Santa Fe, Argentina", areas: "3", ctrol: "5", hs: "200", status: true},
];
  
  constructor(private modalService: NgbModal, private fb: FormBuilder){
    for(let i=1;i<=10;i++){
      this.allData.push();
    }
  }
  
  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.data = this.fb.group({
      company:[null],
      status:[null],
      province:[null],
      city:[null],
      staff:[null],
    });
  }


  // Modal DELETE
  deleteoObjective() {
    Swal.fire({
      title: '¿Estás seguro?',
      // text: "Al eliminarno perderás los datos",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          '¡Éxito!',
          'Objetivo eliminado con éxito',
          'success'
        )
      }
    })
  }

  cancel() {
    this.modalService.dismissAll();
  }

  openFilters() {
    if(this.openFilter == true) {
      this.openFilter = false
    }else {
      this.openFilter = true
    }
  }

  onSubmit() {

    this.submitted = true;

    if (this.data.invalid) {
        return;
    }

    Swal.fire(
      '¡Éxito!',
      'Tus cambios se guardaron correctamente',
      'success'
    )
    console.log("res:", this.data.getRawValue());
    
  }


}
