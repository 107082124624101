import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'incrementHeight'})

export class IncrementHeight implements PipeTransform {
    transform(end, args:string[]) : any {
        let res = [];
        for (let i = 140; i < end; i++) {
            res.push(i);
            }
            return res;
    }
}