import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-client-documents',
  templateUrl: './client-documents.component.html',
  styleUrls: ['./client-documents.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class ClientDocumentsComponent implements OnInit {

  allData =  [
    { 
      type: "D.N.I.", 
      data_end: { "year": 2024, "month": 3, "day": 7 },
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? ", 
      notification_users: ["emma@gmail.com","joaco@gmail.com", "jjaime@gmail.com"], 
      notification_previous_alert: 3, 
      notification_status: "true"
    },
    { 
      type: "C.U.I.T.", 
      data_end: null,
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? ",
      notification_users: [], 
      notification_previous_alert: "", 
      notification_status: "false"
    },
    { 
      type: "C.U.I.L.", 
      data_end: { "year": 2024, "month": 3, "day": 7 },
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? ",
      notification_users: ["emma@gmail.com","joaco@gmail.com", "jjaime@gmail.com"], 
      notification_previous_alert: "", 
      notification_status: "false"
    },
  ];

  items = [
    {id: 1, name: 'Jonatan J', email: 'jjaime@gmail.com'},
    {id: 2, name: 'Emmanuel P', email: 'emma@gmail.com'},
    {id: 3, name: 'Joaquin B', email: 'joaco@gmail.com'},
    {id: 4, name: 'Edgar R', email: 'agdsa@gmail.com'},
  ];

  items_type : any[] = [
    { name: 'Agregar nuevo' },
    { name: 'D.N.I.' },
    { name: 'C.U.I.L.' },
    { name: 'C.U.I.T.' },
    { name: 'Impuesto' },
    { name: 'Servicio' }  
  ];
  
  submitType: string;
  submitTypeEditValue: string;
  
  data: FormGroup;
  data_type: FormGroup;
  enableTitle = false;
  submitted = false;
  currentDate = new Date();
  files: File[] = [];
  private modalRef;
  selectedType = null;

  // Modal Alert
  private modalRefAlert;
  data_alert: FormGroup;

  // DISABLED ON EDIT
  disabled_on_edit:boolean = true;
  
  constructor(private fb: FormBuilder, private modalService: NgbModal, private cdRef:ChangeDetectorRef){
    for(let i=1;i<=10;i++){
      this.allData.push();
    }
  }
  
  ngOnInit() {
    this.initForm();
    this.initFormType();
    this.initFormAlert();
  }

  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }
  

  initForm() {
    this.data = this.fb.group({
      type:[null, Validators.required],
      description:[''],
      data_end:[{
        "year": this.currentDate.getFullYear(),
        "month": this.currentDate.getMonth() + 1,
        "day": this.currentDate.getDate()
      }],
      send_notification: ['']
    });
  }

  initFormType() {
    this.data_type = this.fb.group({
      name:['', Validators.required],
    });
  }

   // Modal EDIT and NEW
  openModalEdit(targetModal, data) {

    this.disabled_on_edit = true;

    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
   
    this.data.patchValue({
      type: data.type,
      description: data.description,
      data_end: data.data_end
    });
  }

  openModalNew(targetModal) {

    this.disabled_on_edit = false;

    this.initForm();
    this.cdRef.detectChanges();

    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  get f() { return this.data.controls; }
   
  onSubmit() {

    this.submitted = true;

    if (this.data.invalid) {
        return;
    }

    Swal.fire(
      '¡Éxito!',
      'Tus cambios se guardaron correctamente',
      'success'
    )
    this.modalService.dismissAll();
    console.log("res:", this.data.getRawValue());
    this.selectedType = [];

  }

  //  DELETE 
  delete() {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          '¡Éxito!',
          'Eliminado correctamente',
          'success'
        )
      }
    })
  }

  //Files Dropzone
  onSelect(event) {
    if (this.files.length < 2) {
      console.log(event);
      this.files.push(...event.addedFiles);
    }
  }

  deleteFile(event) {
    Swal.fire({
      title: '¿Está seguro que quiere eliminar este archivo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar'
    }).then((result) => {
      if (result.value) {
        this.files.splice(this.files.indexOf(event), 1);
        Swal.fire(
          '¡Éxito!',
          'Archivo eliminado',
          'success'
        )
      }
    })
  }



  /// FORM TYPES
  openModalTypeEdit(targetModal, data_type) {
    this.modalRef = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
   
    this.submitType = 'edit';
    this.submitTypeEditValue = data_type.name;

    this.data_type.patchValue({
      name: data_type.name,
    });
  }

  openModalTypeNew(targetModal) {
    this.initFormType();
    this.selectedType = [];
    this.cdRef.detectChanges();

    this.submitType = 'new';

    this.modalRef = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  modalDismiss() {
    this.selectedType = [];
    this.modalRef.close();  
  }

  get fn() { return this.data_type.controls; }
   
  onSubmitType() {

    this.submitted = true;

    if (this.data_type.invalid) {
        return;
    }

    if(this.submitType == 'edit') {
      this.items_type.find(item => item.name == this.submitTypeEditValue).name =  this.data_type.value.name;
      this.items_type = [...this.items_type];
      this.data.get('type').setValue(this.data_type.value.name)
    }

    if(this.submitType == 'new') {
      this.items_type = [...this.items_type, this.data_type.getRawValue()];
      this.data.get('type').setValue(this.data_type.value.name)
    }

    this.modalRef.close();
    this.cdRef.detectChanges();
  }

  deleteType(index) {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {

        this.items_type.splice(index,1);
        this.items_type = [...this.items_type];

        this.selectedType = [];

        this.cdRef.detectChanges();
        
        console.log(this.items_type);

        Swal.fire(
          '¡Éxito!',
          'Eliminado correctamente',
          'success'
        )
      }
    })
  }

  nextInput(target) {
    const field = document.getElementById(target);
    if (field) {
      field.focus();
    }
  }

  /// FORM ALERT
  openModalAlertEdit(targetModal, data_alert) {
    this.modalRefAlert = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });

    this.data_alert.patchValue({
      notification_users: data_alert.notification_users,
      notification_previous_alert: data_alert.notification_previous_alert,
      notification_status: data_alert.notification_status
    });
  }

  initFormAlert() {
    this.data_alert = this.fb.group({
      notification_users: [null, Validators.required],
      notification_previous_alert: ['', [Validators.required, Validators.min(1)]],
      notification_status: ['']
    });
  }

  modalDismissAlert() {
    this.modalRefAlert.close();  
  }

  get fna() { return this.data_alert.controls; }
   
  onSubmitAlert() {

    this.submitted = true;

    if (this.data_alert.invalid) {
        return;
    }

    console.log("res:", this.data_alert.getRawValue());
    this.modalRefAlert.close();
    this.cdRef.detectChanges();
  }

}