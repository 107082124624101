import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {

  volume = true;
  bell = true;

  constructor() { }

  ngOnInit() {
  }

  changeVolume() {
    if(this.volume) {
      this.volume = false;
    }else {
      this.volume = true;
    }
  }
  
  changeNotification() {
    if(this.bell) {
      this.bell = false;
    }else {
      this.bell = true;
    }
  }

  logout() {
    console.log('el usuario quiere salir')
  }

}
