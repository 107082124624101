import { Component, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2'


@Component({
  selector: 'app-client-objective-staff',
  templateUrl: './client-objective-staff.component.html',
  styleUrls: ['./client-objective-staff.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class ClientObjectiveStaffComponent implements OnInit {
  employees = [
    'Moises Darín',
    'Daniel Cristaldo',
    'Samuel Carranza',
    'Joaquin Bustos',
    'Moises Darín',
    'Daniel Cristaldo',
    'Samuel Carranza',
    'Joaquin Bustos'
  ];

  assigned_employees  = [
    'Jonatan Jaime'
  ];

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }
  

  constructor() { }

  ngOnInit() {
  }

  save() {
    Swal.fire(
      '¡Éxito!',
      'Se ha guardado correctamente',
      'success'
    )
  }
}
