import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-client-contrac',
  templateUrl: './client-contrac.component.html',
  styleUrls: ['./client-contrac.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class ClientContracComponent implements OnInit {


  allData = [
    {
      title: "Título del contrato",
      date_start: { "year": 2022, "month": 3, "day": 7 },
      date_end: { "year": 2024, "month": 3, "day": 7 },
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? ",
      notification_users: ["emma@gmail.com","joaco@gmail.com", "jjaime@gmail.com"],
      notification_previous_alert: 3,
      notification_status: "true"
    },
    {
      title: "Título del contrato 1",
      date_start: { "year": 2022, "month": 3, "day": 7 },
      date_end: { "year": 2024, "month": 3, "day": 7 },
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? ",
      notification_users: ["emma@gmail.com","joaco@gmail.com", "jjaime@gmail.com"],
      notification_previous_alert: 3,
      notification_status: "true"
    },
  ];

  files: File[] = [];
  filesUpload = [
    { name: "archivo.png" },
    { name: "archivo2.jpg" },
    { name: "archivo3.pdf" },
  ];

  modalData = [];

  // Modal New
  data: FormGroup;
  currentDate = new Date();
  disabled_on_edit:boolean = true;
  selectedType = null;

  // Modal Alert
  private modalRefAlert;
  data_alert: FormGroup;
  submitted = false;

  // Modal Reason
  private modalRefReason;
  selectedReason = null;
  data_reason: FormGroup;
  submitReason: string;
  submitReasonEditValue: string;

  items_reason : any[] = [
    { name: 'Agregar nuevo', disabled: true },
    { name: 'Ítem 1'},
    { name: 'Ítem 2'},
    { name: 'Ítem 3'}
  ];
  // end Modal Reason

  // Modal Reason TYPE
  private modalRefReasonType;
  selectedReasonType = null;
  data_reason_type: FormGroup;
  submitReasonType: string;
  submitReasonTypeEditValue: string;
  // end Modal Reason TYPE


  constructor(private fb: FormBuilder, private modalService: NgbModal, private cdRef:ChangeDetectorRef) { }

  ngOnInit(): void {
    this.initForm();
    this.initFormAlert();
    this.initFormReason();
    this.initFormReasonType();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  //Files Dropzone
  onSelect(event) {
    if (this.files.length < 2) {
      console.log(event);
      this.files.push(...event.addedFiles);
    }
  }

  deleteFile(event) {
    Swal.fire({
      title: '¿Está seguro que quiere eliminar este archivo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar'
    }).then((result) => {
      if (result.value) {
        this.files.splice(this.files.indexOf(event), 1);
        Swal.fire(
          '¡Éxito!',
          'Archivo eliminado',
          'success'
        )
      }
    })
  }


  initForm() {
    this.data = this.fb.group({
      title:[null, Validators.required],
      date_start:[null, Validators.required],
      date_end:[null, Validators.required],
      description:['', Validators.required]
    });
  }


  openModalNew(targetModal) {

    this.disabled_on_edit = false;

    this.initForm();
    this.cdRef.detectChanges();

    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  get f() { return this.data.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.data.invalid) {
        return;
    }

    Swal.fire(
      '¡Éxito!',
      'Tus cambios se guardaron correctamente',
      'success'
    )
    this.modalService.dismissAll();
    console.log("res:", this.data.getRawValue());
    this.selectedType = [];
  }

  // Modal View
  openModal(targetModal, data) {
    console.log(data);
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static',
      size: 'lg'
    });

    this.modalData = data;
  }


  /// FORM ALERT
  openModalAlertEdit(targetModal, data_alert) {
    this.modalRefAlert = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });

    this.data_alert.patchValue({
      notification_users: data_alert.notification_users,
      notification_previous_alert: data_alert.notification_previous_alert,
      notification_status: data_alert.notification_status
    });
  }

  initFormAlert() {
    this.data_alert = this.fb.group({
      notification_users: [null, Validators.required],
      notification_previous_alert: ['', [Validators.required, Validators.min(1)]],
      notification_status: ['']
    });
  }

  modalDismissAlert() {
    this.modalRefAlert.close();
  }

  get fna() { return this.data_alert.controls; }

  onSubmitAlert() {

    this.submitted = true;

    if (this.data_alert.invalid) {
        return;
    }

    console.log("res:", this.data_alert.getRawValue());
    this.modalRefAlert.close();
    this.cdRef.detectChanges();
  }






  /// FORM REASON
  openModalReasonEdit(targetModal) {
    this.modalRefReason = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  initFormReason() {
    this.data_reason = this.fb.group({
      date_end:['', Validators.required],
      name:['', Validators.required],
      description:[''],
    });
  }

  openModalReasonNew(targetModal) {
    this.initFormReason();
    this.cdRef.detectChanges();
    this.modalRefReason = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  modalDismissReason() {
    this.modalRefReason.close();
  }

  get fn_reason() { return this.data_reason.controls; }

  onSubmitReason() {

    this.submitted = true;

    if (this.data_reason.invalid) {
        return;
    }

    Swal.fire(
      '¡Éxito!',
      'Contrato dado de baja',
      'success'
    )

    this.modalService.dismissAll();
    console.log("res:", this.data_reason.getRawValue());
    this.selectedReason = [];
  }
  /// END FORM REASON



  /// FORM REASON TYPE
  openModalReasonTypeEdit(targetModal, reason) {
    this.modalRefReasonType = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });

    this.submitReasonType = 'edit';
    this.submitReasonTypeEditValue = reason.name;

    this.data_reason_type.patchValue({
      name: reason.name,
    });
  }

  initFormReasonType() {
    this.data_reason_type = this.fb.group({
      name:['', Validators.required]
    });
  }

  openModalReasonTypeNew(targetModal) {
    this.initFormReason();
    this.cdRef.detectChanges();

    this.submitReasonType = 'new';

    this.modalRefReasonType = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  modalDismissReasonType() {
    this.modalRefReason.close();
  }

  get fn_reason_type() { return this.data_reason_type.controls; }

  onSubmitReasonType() {

    this.submitted = true;

    if (this.data_reason_type.invalid) {
        return;
    }

    if(this.submitReasonType == 'edit') {
      this.items_reason.find(item => item.name == this.submitReasonTypeEditValue).name = this.data_reason_type.value.name;
      this.items_reason = [...this.items_reason];
      this.data_reason.get('name').setValue(this.data_reason_type.value.name)
    }

    if(this.submitReason == 'new') {
      this.items_reason = [...this.items_reason, this.data_reason_type.getRawValue()];
      this.data_reason.get('name').setValue(this.data_reason_type.value.name)
    }

    this.modalRefReasonType.close();
    this.cdRef.detectChanges();
  }

  deleteReasonType(index) {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {

        this.items_reason.splice(index,1);
        this.items_reason = [...this.items_reason];

        this.selectedReason = [];

        this.cdRef.detectChanges();

        console.log(this.items_reason);

        Swal.fire(
          '¡Éxito!',
          'Eliminado correctamente',
          'success'
        )
      }
    })
  }

  /// END FORM REASON
}
