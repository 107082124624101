import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from  '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as Chart from 'chart.js';
import {NgbProgressbarConfig} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-client-objective-detail',
  templateUrl: './client-objective-detail.component.html',
  styleUrls: ['./client-objective-detail.component.scss'],
  providers: [NgbProgressbarConfig],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class ClientObjectiveDetailComponent implements OnInit, AfterViewInit  {


  canvas: any;
  ctx: any;
  colors = {
    red: 'rgba(255, 99, 132, 0.5)',
    orange: 'rgba(255, 159, 64, 0.5)',
    yellow: 'rgba(255, 205, 86, 0.5)',
    green: 'rgba(75, 192, 192, 0.5)',
    blue: 'rgba(54, 162, 235, 0.5)',
    purple: 'rgba(153, 102, 255, 0.5)',
    grey: 'rgba(231,233,237, 0.5)',
    grid_line: 'rgba(255,255,255, 0.5)'
  };

  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  map: google.maps.Map;
  lat = 40.73061;
  lng = -73.935242;

  coordinates = new google.maps.LatLng(this.lat, this.lng);

  mapOptions: google.maps.MapOptions = {
   center: this.coordinates,
   zoom: 8
  };

  marker = new google.maps.Marker({
    position: this.coordinates,
    map: this.map,
  });

  
  constructor(config: NgbProgressbarConfig) {
    config.max = 100;
    config.striped = false;
    config.animated = true;
    config.height = '35px';
   }
  
  ngOnInit() { }

  ngAfterViewInit() {
    this.mapInitializer();
    
    // CHART JS
      this.canvas = document.getElementById('line');
      this.ctx = this.canvas.getContext('2d');
      Chart.defaults.global.defaultFontColor = 'white';
      
      let myChart = new Chart(this.ctx, {
        type: 'line',  
        data: {
          labels: [
            "Happiness", "Loneliness", "Health","Managing at Home", "Finances"],
          datasets: [{
            label: 'Data 1',
            backgroundColor: this.colors.red,
            borderColor: this.colors.red,
            pointBackgroundColor: this.colors.red,
            data: [8,1,5,2,4],
          }, {
            label: 'Data 2',
            backgroundColor: this.colors.blue,
            borderColor: this.colors.blue,
            pointBackgroundColor: this.colors.blue,
            data: [10,3,4,3,5],
          }]
        },
        options: {
          responsive: true,
          legend: {
            display: false
           },
           tooltips: {
              enabled: false
           },
           animation: {
            animateScale: true,
            animateRotate: true
          }
        }
      });    
  
      //Radar 
      this.canvas = document.getElementById('pie');
      this.ctx = this.canvas.getContext('2d');
      Chart.defaults.global.defaultFontColor = 'white';
      Chart.defaults.global.defaultFontFamily = 'Montserrat';
      let radar = new Chart(this.ctx, {
        type: 'pie',
        data: {
          labels: [
            "Happiness", "Loneliness"
          ],
          datasets: [
            {
                backgroundColor: [this.colors.blue, this.colors.red],
                borderColor: 'transparent',
                data: [5, 95]
            }
          ]
        },
        options: {
          responsive: true,
          chartArea: { backgroundColor: '#ffffff' },
          legend: {
            position: 'right',
            labels: {
              fontColor: "#ffffff",
              fontSize: 14
            },
          },
          title: {
            display: false,
          }
        }
      });
    }

  mapInitializer() {
    this.map = new google.maps.Map(this.gmap.nativeElement, 
    this.mapOptions);
    this.marker.setMap(this.map);
  }


  //CHART JS

  
}
