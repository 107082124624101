import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {LocationStrategy, Location, PathLocationStrategy} from '@angular/common';

//LocalStorage
import { StorageServiceModule } from 'ngx-webstorage-service';
import { ReactiveFormsModule } from '@angular/forms';

//Plugins
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxPaginationModule } from 'ngx-pagination';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgChartjsModule } from 'ng-chartjs';

import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

import { QRCodeModule } from 'angularx-qrcode';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AgmCoreModule } from '@agm/core';
import { NgxCurrencyModule } from "ngx-currency";
import { ImageCropperModule } from 'ngx-image-cropper';
import { CreditCardDirectivesModule } from 'angular-cc-library';

// Filters / Pipes
import { NgbDateCustomParserFormatter} from '../app/filter/dateformat';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { IncrementNumber } from '../app/filter/increment_number.pipe';
import { IncrementHeight } from '../app/filter/increment_height.pipe';

// Animations
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Rutas
import { APP_ROUTES } from './app.routes';

//Pages
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { NavigationComponent } from './shared/navigation/navigation.component';

// Pages - Company
import { CompanyComponent } from './pages/company/company.component';
import { CompanyDetailComponent } from './pages/company/company-detail/company-detail.component';
import { CompanyBranchesComponent } from './pages/company/company-branches/company-branches.component';
import { CompanyBanksComponent } from './pages/company/company-banks/company-banks.component';
import { CompanyInsuranceComponent } from './pages/company/company-insurance/company-insurance.component';
import { CompanyMedicineComponent } from './pages/company/company-medicine/company-medicine.component';
import { CompanyQualificationsComponent } from './pages/company/company-qualifications/company-qualifications.component';
import { CompanyDocumentsComponent } from './pages/company/company-documents/company-documents.component';
import { CompanyAbsenceComponent } from './pages/company/company-absence/company-absence.component';
import { CompanyConfigurationComponent } from './pages/company/company-configuration/company-configuration.component';
import { CompanyPenaltyComponent } from './pages/company/company-penalty/company-penalty.component';

// Pages - Client
import { ClientsComponent } from './pages/client/clients/clients.component';
import { ClientComponent } from './pages/client/client.component';
import { ClientBanksComponent } from './pages/client/client-banks/client-banks.component';
import { ClientDetailComponent } from './pages/client/client-detail/client-detail.component';
import { ClientDocumentsComponent } from './pages/client/client-documents/client-documents.component';
import { ClientQualificationsComponent } from './pages/client/client-qualifications/client-qualifications.component';
import { ClientFinancesComponent } from './pages/client/client-finances/client-finances.component';
import { ClientContactsComponent } from './pages/client/client-contacts/client-contacts.component';

import { ClientObjectivesComponent } from './pages/client/client-objectives/client-objectives.component';
import { ClientObjectiveComponent } from './pages/client/client-objective/client-objective.component';
import { ClientObjectiveDetailComponent } from './pages/client/client-objective-detail/client-objective-detail.component';
import { ClientObjectiveAreasComponent } from './pages/client/client-objective-areas/client-objective-areas.component';
import { ClientObjectiveCheckpointsComponent } from './pages/client/client-objective-checkpoints/client-objective-checkpoints.component';
import { ClientObjectiveCheckpointsRoundManagementComponent } from './pages/client/client-objective-checkpoints-round-management/client-objective-checkpoints-round-management.component';
import { ClientObjectiveStaffComponent } from './pages/client/client-objective-staff/client-objective-staff.component';
import { ClientObjectiveScheduleComponent } from './pages/client/client-objective-schedule/client-objective-schedule.component';
import { ClientObjectiveElementsComponent } from './pages/client/client-objective-elements/client-objective-elements.component';
import { ClientObjectiveContactsComponent } from './pages/client/client-objective-contacts/client-objective-contacts.component';
import { ClientObjectiveCheckpointsRoundManagementEditComponent } from './pages/client/client-objective-checkpoints-round-management-edit/client-objective-checkpoints-round-management-edit.component';
import { ClientObjectiveRoundsComponent } from './pages/client/client-objective-rounds/client-objective-rounds.component';
import { ClientContracComponent } from './pages/client/client-contrac/client-contrac.component';
import { ClientHistoricalComponent } from './pages/client/client-historical/client-historical.component';
import { ClientObjectiveCheckpointsTaskComponent } from './pages/client/client-objective-checkpoints-task/client-objective-checkpoints-task.component';

// Pages - Employee
import { EmployeesComponent } from './pages/employee/employees/employees.component';
import { EmployeeComponent } from './pages/employee/employee.component';
import { EmployeeDetailComponent } from './pages/employee/employee-detail/employee-detail.component';
import { EmployeeDocumentsComponent } from './pages/employee/employee-documents/employee-documents.component';
import { EmployeeQualificationsComponent } from './pages/employee/employee-qualifications/employee-qualifications.component';
import { EmployeeCareerComponent } from './pages/employee/employee-career/employee-career.component';
import { EmployeeInventoryComponent } from './pages/employee/employee-inventory/employee-inventory.component';
import { EmployeeHistoryComponent } from './pages/employee/employee-history/employee-history.component';
import { EmployeeHrComponent } from './pages/employee/employee-hr/employee-hr.component';
import { EmployeeMedicalRecordComponent } from './pages/employee/employee-medical-record/employee-medical-record.component';
import { EmployeePerformanceComponent } from './pages/employee/employee-performance/employee-performance.component';
import { EmployeePenaltyComponent } from './pages/employee/employee-penalty/employee-penalty.component';
import { EmployeeVehicleComponent } from './pages/employee/employee-vehicle/employee-vehicle.component';
import { EmployeeBanksComponent } from './pages/employee/employee-banks/employee-banks.component';
import { EmployeeAbsenceComponent } from './pages/employee/employee-absence/employee-absence.component';
import { EmployeeContracComponent } from './pages/employee/employee-contrac/employee-contract.component';
import { EmployeeHistoricalComponent } from './pages/employee/employee-historical/employee-historical.component';

// Pages - Dashboard
import { DashboardComponent } from './pages/dashboard/dashboard.component';

// Pages - Configuration
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { ConfigurationSegurityComponent } from './pages/configuration/configuration-segurity/configuration-segurity.component';
import { ConfigurationRoleComponent } from './pages/configuration/configuration-role/configuration-role.component';
import { ConfigurationRolePermissionsComponent } from './pages/configuration/configuration-role-permissions/configuration-role-permissions.component';
import { ConfigurationUserPermissionsComponent } from './pages/configuration/configuration-user-permissions/configuration-user-permissions.component';

// Pages - Providers
import { ProvidersComponent } from './pages/providers/providers.component';
import { ProviderComponent } from './pages/providers/provider/provider.component';
import { ProviderBanksComponent } from './pages/providers/provider-banks/provider-banks.component';
import { ProviderDetailComponent } from './pages/providers/provider-detail/provider-detail.component';
import { ProviderContactComponent } from './pages/providers/provider-contact/provider-contact.component';
import { ProviderShopComponent } from './pages/providers/provider-shop/provider-shop.component';
import { ProviderRefundComponent } from './pages/providers/provider-refund/provider-refund.component';
import { ProviderCostsComponent } from './pages/providers/provider-costs/provider-costs.component';
import { ProviderInsuranceComponent } from './pages/providers/provider-insurance/provider-insurance.component';


// Pages - User
import { UserComponent } from './pages/user/user.component';
import { UserProfileComponent } from './pages/user/user-profile/user-profile.component';
import { UserPaymentMethodsComponent } from './pages/user/user-payment-methods/user-payment-methods.component';
import { UserBillingHistoryComponent } from './pages/user/user-billing-history/user-billing-history.component';
import { UserBillingInformationComponent } from './pages/user/user-billing-information/user-billing-information.component';
import { UserNotificationsComponent } from './pages/user/user-notifications/user-notifications.component';



@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    NavigationComponent,
    DashboardComponent,
    CompanyComponent,
    CompanyDetailComponent,
    CompanyBranchesComponent,
    CompanyBanksComponent,
    CompanyInsuranceComponent,
    CompanyQualificationsComponent,
    CompanyDocumentsComponent,
    CompanyMedicineComponent,
    CompanyAbsenceComponent,
    ClientsComponent,
    ClientComponent,
    ClientDetailComponent,
    ClientBanksComponent,
    ClientDocumentsComponent,
    ClientQualificationsComponent,
    ClientFinancesComponent,
    ClientContactsComponent,
    ClientObjectivesComponent,
    ClientObjectiveDetailComponent,
    ClientObjectiveComponent,
    ClientObjectiveAreasComponent,
    ClientObjectiveCheckpointsComponent,
    ClientObjectiveStaffComponent,
    ClientObjectiveScheduleComponent,
    ClientObjectiveElementsComponent,
    ClientObjectiveContactsComponent,
    EmployeeComponent,
    EmployeeDetailComponent,
    EmployeeDocumentsComponent,
    EmployeeQualificationsComponent,
    EmployeeCareerComponent,
    EmployeeInventoryComponent,
    EmployeeHistoryComponent,
    EmployeeHrComponent,
    EmployeeMedicalRecordComponent,
    EmployeePerformanceComponent,
    EmployeesComponent,
    EmployeeVehicleComponent,
    EmployeeBanksComponent,
    EmployeeAbsenceComponent,
    EmployeePenaltyComponent,
    EmployeeContracComponent,
    EmployeeHistoricalComponent,
    ClientObjectiveCheckpointsTaskComponent,
    ClientObjectiveCheckpointsRoundManagementComponent,
    ClientObjectiveCheckpointsRoundManagementEditComponent,
    ClientObjectiveRoundsComponent,
    ConfigurationComponent,
    ConfigurationSegurityComponent,
    ConfigurationRoleComponent,
    ConfigurationRolePermissionsComponent,
    ConfigurationUserPermissionsComponent,
    ProvidersComponent,
    ProviderComponent,
    ProviderBanksComponent,
    ProviderDetailComponent,
    ProviderContactComponent,
    ProviderShopComponent,
    ProviderRefundComponent,
    ProviderCostsComponent,
    ProviderInsuranceComponent,
    IncrementNumber,
    IncrementHeight,
    ClientContracComponent,
    ClientHistoricalComponent,
    CompanyPenaltyComponent,
    CompanyConfigurationComponent,
    UserComponent,
    UserProfileComponent,
    UserPaymentMethodsComponent,
    UserBillingHistoryComponent,
    UserBillingInformationComponent,
    UserNotificationsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    APP_ROUTES,
    StorageServiceModule,
    NgxDropzoneModule,
    NgxPaginationModule,
    ColorPickerModule,
    NgbModule,
    ReactiveFormsModule,
    NgChartjsModule,
    NgChartjsModule.registerPlugin(),
    NgSelectModule,
    FormsModule,
    QRCodeModule,
    DragDropModule,
    NgxCurrencyModule,
    ImageCropperModule,
    CreditCardDirectivesModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBnKE3RIsDlZk6I_tGEhK3R6W5d6Wx3xsY',
      libraries: ['places']
    })
  ],
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}, {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}],
  bootstrap: [AppComponent]
})
export class AppModule { }
