import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-company-penalty',
  templateUrl: './company-penalty.component.html',
  styleUrls: ['./company-penalty.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class CompanyPenaltyComponent implements OnInit {

  openFilter = false;

  allData =  [
    {
      name: "Michael Jackson",
      type: "Tipo 1",
      motive: "Lesión en mano derecha",
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? ",
      penalty_days: 4,
      start_date: { "year": 2021, "month": 3, "day": 7 },
      end_date: { "year": 2024, "month": 3, "day": 7 },
      audit: "Jonatan Jaime",
      notification_users: ["emma@gmail.com","joaco@gmail.com", "jjaime@gmail.com"],
      notification_previous_alert: 3,
      notification_status: "true",
      enjoy_salary: "true",
    },
    {
      name: "Lorenzo Apellido",
      type: "Tipo 2",
      motive: "Lesión en mano derecha",
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? ",
      penalty_days: 34,
      start_date: { "year": 2021, "month": 3, "day": 7 },
      end_date: { "year": 2024, "month": 3, "day": 7 },
      audit: "Jonatan Jaime",
      notification_users: ["emma@gmail.com","joaco@gmail.com", "jjaime@gmail.com"],
      notification_previous_alert: 3,
      notification_status: "true",
      enjoy_salary: "true",
    },
    {
      name: "Joaquin Apellido",
      type: "Tipo 4",
      motive: "Lesión en mano derecha",
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? ",
      penalty_days: 47,
      start_date: { "year": 2021, "month": 3, "day": 7 },
      end_date: null,
      audit: "Jonatan Jaime",
      notification_users: ["emma@gmail.com","joaco@gmail.com", "jjaime@gmail.com"],
      notification_previous_alert: 3,
      notification_status: "true",
      enjoy_salary: "true",
    },
    {
      name: "Emmanuel Apellido",
      type: "Tipo 3",
      motive: "Lesión en mano derecha",
      penalty_days: 42,
      start_date: { "year": 2021, "month": 3, "day": 7 },
      end_date: null,
      audit: "Jonatan Jaime",
      notification_users: [],
      notification_previous_alert: "",
      notification_status: "true",
      enjoy_salary: "true",
    }
  ];

  items = [
    {id: 1, name: 'Jonatan J', email: 'jjaime@gmail.com'},
    {id: 2, name: 'Emmanuel P', email: 'emma@gmail.com'},
    {id: 3, name: 'Joaquin B', email: 'joaco@gmail.com'},
    {id: 4, name: 'Edgar R', email: 'agdsa@gmail.com'},
  ];


  filters_type : any[] = [
    { name: 'Tipo 1' },
    { name: 'Tipo 2' },
    { name: 'Tipo 3' },
    { name: 'Tipo 4' }
  ];
  filters_motive : any[] = [
    { name: 'Ingreso demorado' },
    { name: 'Abuso de autoridad' },
    { name: 'Ausencia injustificad' }
  ];

  filters_audit : any[] = [
    { name: 'Auditor 1' },
    { name: 'Auditor 2' },
    { name: 'Auditor 3' }
  ];

  filters_employee : any[] = [
    { name: 'Empleado 1' },
    { name: 'Empleado 2' },
    { name: 'Empleado 3' }
  ];

  items_type : any[] = [
    { name: 'Agregar nuevo' },
    { name: 'Tipo 1' },
    { name: 'Tipo 2' },
    { name: 'Tipo 3' },
    { name: 'Tipo 4' }
  ];

  items_motive : any[] = [
    { name: 'Agregar nuevo' },
    { name: 'Ítem 1' },
    { name: 'Ítem 2' },
    { name: 'Ítem 3' },
    { name: 'Lesión en mano derecha' }
  ];

  items_audit : any[] = [
    { name: 'Jonatan J.' },
    { name: 'Emmanuel P.' },
    { name: 'Joaquín B.' },
    { name: 'Daniel C.' }
  ];


  submitType: string;
  submitTypeEditValue: string;
  
  data: FormGroup;
  dataFilter: FormGroup;
  data_type: FormGroup;
  enableTitle = false;
  submitted = false;
  currentDate = new Date();
  files: File[] = [];
  private modalRef;
  selectedType = null;

  // Modal Alert
  private modalRefAlert;
  data_alert: FormGroup;

  // Modal Motive
  submitMotive: string;
  submitMotiveEditValue: string;
  data_motive: FormGroup;
  selectedMotive = null;
  
  // DISABLED ON EDIT
  disabled_on_edit:boolean = true;

  constructor(private fb: FormBuilder, private modalService: NgbModal, private cdRef:ChangeDetectorRef){
    for(let i=1;i<=10;i++){
      this.allData.push();
    }
  }

  ngOnInit() {
    this.initForm();
    this.initFormType();
    this.initFormMotive();
    this.initFormAlert();
    this.initFormFilters();
  }

  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  initForm() {
    this.data = this.fb.group({
      type:[null, Validators.required],
      motive:[null, Validators.required],
      audit:[null, Validators.required],
      description:[''],
      penalty_days:['', Validators.required],
      days_requested:[''],
      start_date:[''],
      end_date:['', Validators.required],
      enjoy_salary:[''],
      subject_files:['']
    });
  }

  initFormFilters() {
    this.dataFilter = this.fb.group({
      type:[null],
      motive:[null],
      audit:[null],
      employee:[null],
      start_date:[null],
      end_date:[null],
    });
  }

  initFormType() {
    this.data_type = this.fb.group({
      name:['', Validators.required],
    });
  }

  initFormMotive() {
    this.data_motive = this.fb.group({
      name:['', Validators.required],
    });
  }

   // Modal EDIT and NEW
  openModalEdit(targetModal, data) {

    this.disabled_on_edit = true;

    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
   
    this.data.patchValue({
      type: data.type,
      motive: data.motive,
      audit: data.audit,
      description: data.description,
      breach_date: data.breach_date,
      days_requested: data.days_requested,
      start_date: data.start_date,
      reinstatement_date: data.reinstatement_date,
      enjoy_salary: data.enjoy_salary,
      subject_files: data.subject_files,
    });
  }

  openModalNew(targetModal) {

    this.disabled_on_edit = false;

    this.initForm();
    this.cdRef.detectChanges();

    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  get f() { return this.data.controls; }
   
  onSubmit() {

    this.submitted = true;

    if (this.data.invalid) {
        return;
    }

    Swal.fire(
      '¡Éxito!',
      'Tus cambios se guardaron correctamente',
      'success'
    )
    this.modalService.dismissAll();
    console.log("res:", this.data.getRawValue());
    this.selectedType = [];

  }

  //  DELETE 
  delete() {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          '¡Éxito!',
          'Eliminado correctamente',
          'success'
        )
      }
    })
  }

  //Files Dropzone
  onSelect(event) {
    if ( this.files.length < 50 ) {
      console.log(event);
      this.files.push(...event.addedFiles);
    }
  }

  deleteFile(event) {
    Swal.fire({
      title: '¿Está seguro que quiere eliminar este archivo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar'
    }).then((result) => {
      if (result.value) {
        this.files.splice(this.files.indexOf(event), 1);
        Swal.fire(
          '¡Éxito!',
          'Archivo eliminado',
          'success'
        )
      }
    })
  }



  /// FORM TYPES
  openModalTypeEdit(targetModal, data_type) {
    this.modalRef = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
   
    this.submitType = 'edit';
    this.submitTypeEditValue = data_type.name;

    this.data_type.patchValue({
      name: data_type.name,
    });
  }

  openModalTypeNew(targetModal) {
    this.initFormType();
    this.selectedType = [];
    this.cdRef.detectChanges();

    this.submitType = 'new';

    this.modalRef = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  modalDismiss() {
    this.selectedType = [];
    this.modalRef.close();  
  }

  get fn() { return this.data_type.controls; }
   
  onSubmitType() {

    this.submitted = true;

    if (this.data_type.invalid) {
        return;
    }

    if(this.submitType == 'edit') {
      this.items_type.find(item => item.name == this.submitTypeEditValue).name =  this.data_type.value.name;
      this.items_type = [...this.items_type];
      this.data.get('type').setValue(this.data_type.value.name)
    }

    if(this.submitType == 'new') {
      this.items_type = [...this.items_type, this.data_type.getRawValue()];
      this.data.get('type').setValue(this.data_type.value.name)
    }

    this.modalRef.close();
    this.cdRef.detectChanges();
  }

  deleteType(index) {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {

        this.items_type.splice(index,1);
        this.items_type = [...this.items_type];

        this.selectedType = [];

        this.cdRef.detectChanges();
        
        console.log(this.items_type);

        Swal.fire(
          '¡Éxito!',
          'Eliminado correctamente',
          'success'
        )
      }
    })
  }

  /// FORM MOTIVE
  openModalMotiveEdit(targetModal, data_motive) {
    this.modalRef = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
   
    this.submitMotive = 'edit';
    this.submitMotiveEditValue = data_motive.name;

    this.data_motive.patchValue({
      name: data_motive.name,
    });
  }

  openModalMotiveNew(targetModal) {
    this.initFormMotive();
    this.selectedMotive = [];
    this.cdRef.detectChanges();

    this.submitMotive = 'new';

    this.modalRef = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  modalDismissMotive() {
    this.selectedMotive = [];
    this.modalRef.close();  
  }

  get fn_motive() { return this.data_motive.controls; }
   
  onSubmitMotive() {

    this.submitted = true;

    if (this.data_motive.invalid) {
        return;
    }

    if(this.submitType == 'edit') {
      this.items_motive.find(item => item.name == this.submitTypeEditValue).name =  this.data_motive.value.name;
      this.items_motive = [...this.items_motive];
      this.data.get('motive').setValue(this.data_motive.value.name)
    }

    if(this.submitType == 'new') {
      this.items_motive = [...this.items_motive, this.data_motive.getRawValue()];
      this.data.get('motive').setValue(this.data_motive.value.name)
    }

    this.modalRef.close();
    this.cdRef.detectChanges();
  }

  deleteMotive(index) {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {

        this.items_motive.splice(index,1);
        this.items_motive = [...this.items_motive];

        this.selectedMotive = [];

        this.cdRef.detectChanges();
        
        console.log(this.items_motive);

        Swal.fire(
          '¡Éxito!',
          'Eliminado correctamente',
          'success'
        )
      }
    })
  }

  nextInput(target) {
    const field = document.getElementById(target);
    if (field) {
      field.focus();
    }
  }


  /// FORM ALERT
  openModalAlertEdit(targetModal, data_alert) {
    this.modalRefAlert = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });

    this.data_alert.patchValue({
      notification_users: data_alert.notification_users,
      notification_previous_alert: data_alert.notification_previous_alert,
      notification_status: data_alert.notification_status
    });
  }

  initFormAlert() {
    this.data_alert = this.fb.group({
      notification_users: [null, Validators.required],
      notification_previous_alert: ['', [Validators.required, Validators.min(1)]],
      notification_status: ['']
    });
  }

  modalDismissAlert() {
    this.modalRefAlert.close();
  }

  get fna() { return this.data_alert.controls; }

  onSubmitAlert() {

    this.submitted = true;

    if (this.data_alert.invalid) {
        return;
    }

    console.log("res:", this.data_alert.getRawValue());
    this.modalRefAlert.close();
    this.cdRef.detectChanges();
  }



  /// FILTERS
  openFilters() {
    if(this.openFilter == true) {
      this.openFilter = false
    }else {
      this.openFilter = true
    }
  }


  onSubmitFilters() {

    this.submitted = true;

    if (this.dataFilter.invalid) {
        return;
    }

    // Swal.fire(
    //   '¡Éxito!',
    //   'Tus cambios se guardaron correctamente',
    //   'success'
    // )
    console.log("res:", this.dataFilter.getRawValue());

  }


  //  REACTIVE
  reactive(name) {
    Swal.fire({
      title: '¿Esta seguro que desea reactivar a '+name+'?',
      // text: "Esto no modificara su situación actual",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, reactivarlo'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          '¡Éxito!',
          'Reactivado correctamente',
          'success'
        )
      }
    })
  }

}
