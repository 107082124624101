import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-configuration-segurity',
  templateUrl: './configuration-segurity.component.html',
  styleUrls: ['./configuration-segurity.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class ConfigurationSegurityComponent implements OnInit {

  data: FormGroup;
  allData = [
    {name: "Jonatan Jaime", rol: "Super usuario", approved: "true", last_access: "11/03/20", last_access_hour: "15:52 PM", email: "jonatan.jaime8@gmail.com", status: "Activo", user: "username_123", password:"123"},
    {name: "Pedro Jaime", rol: "Rol test", approved: "true", last_access: "11/03/20", last_access_hour: "15:52 PM", email: "jonatan.jaime8@gmail.com", status: "Activo", user: "username_123", password:"123"},
    {name: "Juan Jaime", rol: "Rol test", approved: "false", last_access: "11/03/20", last_access_hour: "15:52 PM", email: "jonatan.jaime8@gmail.com", status: "Activo", user: "username_123", password:"123"},
    {name: "Pepito Jaime", rol: "Rol test", approved: "false", last_access: "11/03/20", last_access_hour: "15:52 PM", email: "jonatan.jaime8@gmail.com", status: "Activo", user: "username_123", password:"123"},
    {name: "Fran Jaime", rol: "Rol test", approved: "true", last_access: "11/03/20", last_access_hour: "15:52 PM", email: "jonatan.jaime8@gmail.com", status: "Activo", user: "username_123", password:"123"},
    {name: "Jonatan Jaime", rol: "Rol test", approved: "false", last_access: "11/03/20", last_access_hour: "15:52 PM", email: "jonatan.jaime8@gmail.com", status: "Activo", user: "username_123", password:"123"},
    {name: "Pedro Jaime", rol: "Rol test", approved: "true", last_access: "11/03/20", last_access_hour: "15:52 PM", email: "jonatan.jaime8@gmail.com", status: "Activo", user: "username_123", password:"123"},
    {name: "Juan Jaime", rol: "Rol test", approved: "true", last_access: "11/03/20", last_access_hour: "15:52 PM", email: "jonatan.jaime8@gmail.com", status: "Activo", user: "username_123", password:"123"},
    {name: "Pepito Jaime", rol: "Rol test", approved: "false", last_access: "11/03/20", last_access_hour: "15:52 PM", email: "jonatan.jaime8@gmail.com", status: "Activo", user: "username_123", password:"123"},
    {name: "Fran Jaime", rol: "Rol test", approved: "true", last_access: "11/03/20", last_access_hour: "15:52 PM", email: "jonatan.jaime8@gmail.com", status: "Activo", user: "username_123", password:"123"},
    {name: "Jonatan Jaime", rol: "Rol test", approved: "true", last_access: "11/03/20", last_access_hour: "15:52 PM", email: "jonatan.jaime8@gmail.com", status: "Activo", user: "username_123", password:"123"},
    {name: "Pedro Jaime", rol: "Rol test", approved: "true", last_access: "11/03/20", last_access_hour: "15:52 PM", email: "jonatan.jaime8@gmail.com", status: "Activo", user: "username_123", password:"123"},
    {name: "Juan Jaime", rol: "Rol test", approved: "true", last_access: "11/03/20", last_access_hour: "15:52 PM", email: "jonatan.jaime8@gmail.com", status: "Activo", user: "username_123", password:"123"},
    {name: "Pepito Jaime", rol: "Rol test", approved: "true", last_access: "11/03/20", last_access_hour: "15:52 PM", email: "jonatan.jaime8@gmail.com", status: "Activo", user: "username_123", password:"123"},
    {name: "Fran Jaime", rol: "Rol test", approved: "true", last_access: "11/03/20", last_access_hour: "15:52 PM", email: "jonatan.jaime8@gmail.com", status: "Activo", user: "username_123", password:"123"},
  ];

  notifications = [
    {
      name: "Auditoria de elementos",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      email: true,
      visual: false,
      audible: true,
    },
    {
      name: "Auditoria de uniformes",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      email: true,
      visual: true,
      audible: true,
    },
    {
      name: "Botón de pánico",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      email: true,
      visual: true,
      audible: true,
    },
    {
      name: "Caída de equipos",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      email: true,
      visual: false,
      audible: false,
    },
    {
      name: "Cierre de sesión forzado",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      email: false,
      visual: false,
      audible: true,
    },
    {
      name: "Botón de pánico",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      email: true,
      visual: true,
      audible: true,
    },
    {
      name: "Caída de equipos",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      email: true,
      visual: false,
      audible: false,
    },
    {
      name: "Cierre de sesión forzado",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      email: false,
      visual: false,
      audible: true,
    },
    {
      name: "Auditoria de elementos",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      email: true,
      visual: false,
      audible: true,
    },
    {
      name: "Auditoria de uniformes",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      email: true,
      visual: true,
      audible: true,
    },
  ]

  constructor(private modalService: NgbModal, private fb:FormBuilder){
    for(let i=1;i<=10;i++){
      this.allData.push();
    }
  }

  ngOnInit() {
    this.data = this.fb.group({
      email: [''],
      visual: [''],
      audible: [''],
    });
  }

  activate(username) {
    Swal.fire({
      title: '¿Esta seguro que desea activar a ' + username +'?',
      // text: "Esto no modificara su situación actual",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          '¡Éxito!',
          username + ' activado correctamente',
          'success'
        )
      }
    })
  }

  deactivate(username) {
    Swal.fire({
      title: '¿Esta seguro que desea desactivar a ' + username +'?',
      text: "No tendrá más acceso a la plataforma",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          '¡Éxito!',
          username + ' desactivado correctamente',
          'success'
        )
      }
    })
  }

  openNotifications(targetModal){
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static',
      size: 'lg'
    });
  }

  onSubmit() {
    this.modalService.dismissAll();
    console.log("res:", this.data.getRawValue());
    Swal.fire({
      title: 'Se ha creado correctamente',
      icon: 'success',
      confirmButtonText: 'Ok'
    }).then((result) => {
      // if (result.value) {
      //   this.router.navigate(['/configuration/role/permissions']);
      // }
    });
  }

}
