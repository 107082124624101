import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-client-objective',
  templateUrl: './client-objective.component.html',
  styleUrls: ['./client-objective.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class ClientObjectiveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
