import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class ClientsComponent implements OnInit {

  openFilter = false;
  data: FormGroup;
  submitted = false;

  allData = [
    { 
      company: "Cliente 1", 
      full_address: "Rosario, Santa Fe, Argentina",
      city: "Rosario",
      province: "Santa Fe",
      country: "Argentina",
      objectives: "8",
      email: "jonatan.jaime8@gmail.com",
      status: true,
    },
    { 
      company: "Cliente 2", 
      full_address: "Córdoba, Córdoba, Argentina",
      city: "Córdoba",
      province: "Córdoba",
      country: "Argentina",
      objectives: "5",
      email: "jona@gmail.com",
      status: false,
    },
    { 
      company: "Cliente 4", 
      full_address: "Formosa, Chaco, Argentina",
      city: "Formosa",
      province: "Chaco",
      country: "Argentina",
      objectives: "1",
      email: "jonatan.jaime8@gmail.com",
      status: true,
    },
  ];

  province = [
    {name: 'Salta'},
    {name: 'Jujuy'},
    {name: 'Santa Fe'},
    {name: 'Bueno Aires'},
    {name: 'Tucumán'},
  ];

  city = [
    {name: 'Rosario'},
    {name: 'Santa Fe'},
    {name: 'Santa Isabel'},
    {name: 'Venado Tuerto'},
    {name: 'Teodelina'},
    {name: 'Villa Cañas'},
  ];

  status = [
    {name: 'Activo'},
    {name: 'Inactivo'},
    {name: 'Ambos'}
  ];

  constructor(private fb: FormBuilder){
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.data = this.fb.group({
      company:[null],
      status:[null],
      province:[null],
      city:[null],
    });
  }

  openFilters() {
    if(this.openFilter == true) {
      this.openFilter = false
    }else {
      this.openFilter = true
    }
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.dni.toLocaleLowerCase().indexOf(term) > -1 || 
    item.name.toLocaleLowerCase().indexOf(term) > -1;
  }

  onSubmit() {

    this.submitted = true;

    if (this.data.invalid) {
        return;
    }

    Swal.fire(
      '¡Éxito!',
      'Tus cambios se guardaron correctamente',
      'success'
    )
    console.log("res:", this.data.getRawValue());
    
  }

}
