import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { trigger, state, style, animate, transition } from '@angular/animations';


@Component({
  selector: 'app-user-billing-history',
  templateUrl: './user-billing-history.component.html',
  styleUrls: ['./user-billing-history.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class UserBillingHistoryComponent implements OnInit {

  allData = [
    {
      date: { "year": 2024, "month": 3, "day": 7 },
      code: "9908973846329",
      plan: "Plan Premium",
      plan_type: "eCommerce",
      logo: "mastercard",
      creditCard: "1234123412345814",
      status: "paid",
      price: "19,20 US$",
    },
    {
      date: { "year": 2024, "month": 3, "day": 7 },
      code: "44214321432423",
      plan: "Plan Premium",
      plan_type: "eCommerce",
      logo: "visa",
      creditCard: "1234123412345233",
      status: "non-paid",
      price: "19,20 US$",
    },
    {
      date: { "year": 2024, "month": 3, "day": 7 },
      code: "44214321432423",
      plan: "Plan Premium",
      plan_type: "eCommerce",
      logo: "visa",
      creditCard: "1234123412345233",
      status: "paid",
      price: "19,20 US$",
    }
  ];

  //Filtros
  openFilter = false;
  submitted = false;
  data: FormGroup;
  date = [
    {name: 'Semana pasada'},
    {name: 'Mes pasado'},
    {name: 'Año pasado'}
  ];
  status = [
    {name: 'Pagado'},
    {name: 'Impago'}
  ];

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.data = this.fb.group({
      date:[null],
      status:[null]
    });
  }


  openFilters() {
    this.openFilter = !this.openFilter;
  }

  onSubmit() {

    this.submitted = true;

    if (this.data.invalid) {
        return;
    }

    // Swal.fire(
    //   '¡Éxito!',
    //   'Tus cambios se guardaron correctamente',
    //   'success'
    // )
    console.log("res:", this.data.getRawValue());
    
  }

}


