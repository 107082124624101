import { Component, OnInit, ViewChild, ElementRef, NgZone, ChangeDetectorRef } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss']
})
export class ClientDetailComponent implements OnInit  {

  data: FormGroup;
  files: File[] = [];
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;

  @ViewChild('search',{static:false})
  public searchElementRef: ElementRef;


  // Modal Alert
  private modalRefAlert;
  data_alert: FormGroup;
  submitted = false;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private cdRef:ChangeDetectorRef
  ) { }
   

  ngOnInit() {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });

    this.initFormAlert();
  }

  // Get Current Location Coordinates
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
 
    });
  }

  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }
   
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }



  delete(targetModal) {
    Swal.fire({
      title: '¿Está seguro de que quiere dar de baja al cliente?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar'
    }).then((result) => {
      if (result.value) {
        this.modalService.open(targetModal, {
          centered: true,
          backdrop: 'static'
         });
      }
    })
  }

  onSubmit() {
    this.modalService.dismissAll();
    Swal.fire(
      '¡Éxito!',
      'Cliente dado de baja',
      'success'
    )
    
  }



  /// FORM ALERT
  openModalAlertEdit(targetModal, data_alert) {
    this.modalRefAlert = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });

    this.data_alert.patchValue({
      notification_users: data_alert.notification_users,
      notification_previous_alert: data_alert.notification_previous_alert,
      notification_status: data_alert.notification_status
    });
  }

  initFormAlert() {
    this.data_alert = this.fb.group({
      notification_users: [null, Validators.required],
      notification_previous_alert: ['', [Validators.required, Validators.min(1)]],
      notification_status: ['']
    });
  }

  modalDismissAlert() {
    this.modalRefAlert.close();  
  }

  get fna() { return this.data_alert.controls; }
   
  onSubmitAlert() {

    this.submitted = true;

    if (this.data_alert.invalid) {
        return;
    }

    console.log("res:", this.data_alert.getRawValue());
    this.modalRefAlert.close();
    this.cdRef.detectChanges();
  }


}
