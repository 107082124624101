import { Component, OnInit } from '@angular/core';
import {NgbProgressbarConfig} from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as Chart from 'chart.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-employee-performance',
  templateUrl: './employee-performance.component.html',
  styleUrls: ['./employee-performance.component.scss'],
  providers: [NgbProgressbarConfig],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class EmployeePerformanceComponent implements OnInit {

  data: FormGroup;

  canvas: any;
  ctx: any;
  colors = {
    red: 'rgba(255, 99, 132, 0.5)',
    orange: 'rgba(255, 159, 64, 0.5)',
    yellow: 'rgba(255, 205, 86, 0.5)',
    green: 'rgba(75, 192, 192, 0.5)',
    blue: 'rgba(54, 162, 235, 0.5)',
    purple: 'rgba(153, 102, 255, 0.5)',
    grey: 'rgba(231,233,237, 0.5)',
    grid_line: 'rgba(255,255,255, 0.5)'
  };

  allData = [
    {type: "Apercibimiento", reason:"Falta de respeto a su superior", date1: "21/12/19"},
    {type: "Apercibimiento", reason:"Falta de respeto a su superior", date1: "21/12/19"},
    {type: "Apercibimiento", reason:"Falta de respeto a su superior", date1: "21/12/19"},
    {type: "Apercibimiento", reason:"Falta de respeto a su superior", date1: "21/12/19"}
  ];
  
  
  

  constructor(config: NgbProgressbarConfig, private fb: FormBuilder, private modalService: NgbModal) {
    config.max = 100;
    config.striped = false;
    config.animated = true;
    config.height = '35px';

    for(let i=1;i<=10;i++){
      this.allData.push();
    }
  }
 
  ngOnInit() {
    this.data = this.fb.group({
        type: [''],
        auditor: [''],
        client: [''],
        date1: [''],
        date2: [''],
        days: [''],
        reason: ['']
    });
   }

   // Modal EDIT and NEW
   openModalEdit(targetModal, data) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
   
    this.data.patchValue({
      type: data.type,
      auditor: data.auditor,
      client: data.client,
      date1: data.date1,
      date2: data.date2,
      days: data.days,
      reason: data.reason
    });
  }

  openModalNew(targetModal) {
    this.modalService.open(targetModal, {
     centered: true,
     backdrop: 'static'
    });    
  }

   
  onSubmit() {
    this.modalService.dismissAll();
    console.log("res:", this.data.getRawValue());
  }


  
  // Modal DELETE
    
  openModalDelete(targetModal) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });    
}

deleteModal() {
  this.modalService.dismissAll();
  console.log("Quiere eliminar");
}

cancel() {
  this.modalService.dismissAll();
}


  //CHART JS

  ngAfterViewInit() {
    this.canvas = document.getElementById('performance');
    this.ctx = this.canvas.getContext('2d');
    Chart.defaults.global.defaultFontColor = 'white';
    
    let myChart = new Chart(this.ctx, {
      type: 'doughnut',
      data: {
          datasets: [{
              data: [80,20],
              backgroundColor: [
                  '#13c273',
                  'var(--input-color)'
              ],
              hoverBackgroundColor: [
                '#13c273',
                'var(--input-color)'
              ],
              borderWidth: 0
          }],
          labels: [
            '',
            ''
        ]
      },
      options: {
        responsive: true,
        cutoutPercentage: 90,
        plugin_attribute: true,
        // rotation: 1 * Math.PI,
        // circumference: 1 * Math.PI,
        legend: {
          display: false
         },
         tooltips: {
            enabled: false
         },
         animation: {
          animateScale: true,
          animateRotate: true
        }
      }
    });

    Chart.pluginService.register({
      beforeDraw: function(chart) {
      if( chart.config.options.plugin_attribute) {
          var width = chart.chart.width,
              height = chart.chart.height,
              ctx = chart.chart.ctx;
      
          ctx.restore();
          var fontSize = (height / 80).toFixed(2);
              ctx.font = fontSize + "em Montserrat";
              ctx.textBaseline = "middle";
              ctx.fillStyle = 'white';
      
          var text = "80%",
              textX = Math.round((width - ctx.measureText(text).width) / 2),
              textY = height / 2;
      
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      }
    });

    

    //Radar 
    this.canvas = document.getElementById('radar');
    this.ctx = this.canvas.getContext('2d');
    Chart.defaults.global.defaultFontColor = 'white';
    Chart.defaults.global.defaultFontFamily = 'Montserrat';
    let radar = new Chart(this.ctx, {
      type: 'radar',
      data: {
        labels: [
          "Happiness", "Loneliness", "Health","Managing at Home", "Finances"],
        datasets: [{
          label: 'Data 1',
          backgroundColor: this.colors.red,
          borderColor: this.colors.red,
          pointBackgroundColor: this.colors.red,
          data: [8,1,5,2,4],
        }, {
          label: 'Data 2',
          backgroundColor: this.colors.blue,
          borderColor: this.colors.blue,
          pointBackgroundColor: this.colors.blue,
          data: [10,3,4,3,5],
        }]
      },
      options: {
        responsive: true,
        chartArea: { backgroundColor: '#ffffff' },
        legend: {
          position: 'right',
          labels: {
            fontColor: "#ffffff",
            fontSize: 14
          },
        },
        title: {
          display: false,
        },
        scale: {
          angleLines: {
            color: this.colors.grid_line,
          },
          gridLines: {
            color: this.colors.grid_line,
            lineWidth: 2,
          },
        },
      }
    });
  }


}
