import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormGroup, FormBuilder  } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-configuration-user-permissions',
  templateUrl: './configuration-user-permissions.component.html',
  styleUrls: ['./configuration-user-permissions.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class ConfigurationUserPermissionsComponent implements OnInit {

  data: FormGroup;

  
  allData = [
    { permission: "Permiso 1", access: true, edit: true, 
      subpermission: [
        {
          name: "Sub Permiso 1",
          access: true,
          edit: true
        },
        {
          name: "Sub Permiso 2",
          access: true,
          edit: false
        },
        {
          name: "Sub Permiso 3",
          access: false,
          edit: false
        },
        {
          name: "Sub Permiso 4",
          access: false,
          edit: true
        }
      ]
    },
    { permission: "Permiso 2", access: true, edit: false, 
      subpermission: [
        {
          name: "Sub Permiso 1",
          access: true,
          edit: true
        },
        {
          name: "Sub Permiso 2",
          access: true,
          edit: false
        }
      ]
    },
    { permission: "Permiso 3", access: false, edit: true },
    { permission: "Permiso 4", access: false, edit: true },
    { permission: "Permiso 5", access: true, edit: false },
    { permission: "Permiso 6", access: true, edit: false },
    { permission: "Permiso 7", access: false, edit: true },
    { permission: "Permiso 8", access: false, edit: false },
    { permission: "Permiso 9", access: true, edit: true },
    { permission: "Permiso 10", access: true, edit: true },
    { permission: "Permiso 11", access: false, edit: false },
    { permission: "Permiso 12", access: true, edit: true },
  ];



  constructor(private modalService: NgbModal, private fb: FormBuilder, private router: Router) { }

  ngOnInit() {
    this.data = this.fb.group({
      permission: [''],
      access: [''],
      edit: ['']
    });
  }

  
  public toggle( element: HTMLElement, icon: HTMLElement ) {
    element.classList.toggle('d-none');

    if(icon.classList.contains('icon-chevron-right') === true) {
        icon.classList.remove('icon-chevron-right');
        icon.classList.add('icon-chevron-down');
    }else {
      icon.classList.add('icon-chevron-right');
      icon.classList.remove('icon-chevron-down');
    }
  }

  changeSubpermissionAccess(data, i) {
    data[i].access = !data[i].access; 
  }

  changePermissionAccess(data) {
    data.access = !data.access; 

    for (var i = 0; i < data.subpermission.length; i++) {
      data.subpermission[i].access = data.access;
    }
  }

  changeSubpermissionEdit(data, i) {
    data[i].edit = !data[i].edit; 
  }

  changePermissionEdit(data) {
    data.edit = !data.edit; 

    for (var i = 0; i < data.subpermission.length; i++) {
      data.subpermission[i].edit = data.edit;
    }
  }

  save() {
    Swal.fire(
      '¡Éxito!',
      'Se ha guardado correctamente',
      'success'
    )    
  }

    
}
