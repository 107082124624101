import { Component, OnInit } from '@angular/core';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class ProvidersComponent implements OnInit {

  data: FormGroup;

  allData = [
    {name: "Nombre del proveedor", id: "1234566", identification:"27-39118815-2", city: "Rosario",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "47819471", identification:"27-39118815-2", city: "Mendoza",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "0987481", identification:"27-39118815-2", city: "Río Cuarto",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "6618932972", identification:"27-39118815-2", city: "Río Cuarto",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "1234566", identification:"27-39118815-2", city: "Rosario",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "47819471", identification:"27-39118815-2", city: "Mendoza",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "0987481", identification:"27-39118815-2", city: "Río Cuarto",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "6618932972", identification:"27-39118815-2", city: "Río Cuarto",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "1234566", identification:"27-39118815-2", city: "Rosario",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "47819471", identification:"27-39118815-2", city: "Mendoza",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "0987481", identification:"27-39118815-2", city: "Río Cuarto",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "6618932972", identification:"27-39118815-2", city: "Río Cuarto",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "1234566", identification:"27-39118815-2", city: "Rosario",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "47819471", identification:"27-39118815-2", city: "Mendoza",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "0987481", identification:"27-39118815-2", city: "Río Cuarto",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "6618932972", identification:"27-39118815-2", city: "Río Cuarto",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "1234566", identification:"27-39118815-2", city: "Rosario",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "47819471", identification:"27-39118815-2", city: "Mendoza",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "0987481", identification:"27-39118815-2", city: "Río Cuarto",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "6618932972", identification:"27-39118815-2", city: "Río Cuarto",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "1234566", identification:"27-39118815-2", city: "Rosario",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "47819471", identification:"27-39118815-2", city: "Mendoza",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "0987481", identification:"27-39118815-2", city: "Río Cuarto",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
    {name: "Nombre del proveedor", id: "6618932972", identification:"27-39118815-2", city: "Río Cuarto",  phone: "3462619188", email: "jonatan.jaime8@gmail.com"},
  ];
  
  constructor(private fb: FormBuilder, private modalService: NgbModal){
    for(let i=1;i<=10;i++){
      this.allData.push();
    }
  }
  
  ngOnInit() {
    this.data = this.fb.group({
      direction: [''],
      city: [''],
      state: [''],
      phone: [''],
      email: ['']
    });
   }

   // Modal EDIT and NEW
  openModalEdit(targetModal, data) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
   
    this.data.patchValue({
      direction: data.direction,
      city: data.city,
      state: data.state,
      phone: data.phone,
      email: data.email
    });
  }

  openModalNew(targetModal) {
    this.modalService.open(targetModal, {
     centered: true,
     backdrop: 'static'
    });    
  }

   
  onSubmit() {
    this.modalService.dismissAll();
    console.log("res:", this.data.getRawValue());
  }


  // DELETE
  delete(userName) {
    Swal.fire({
      title: '¿Estás seguro de eliminar éste proveedor?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          '¡Éxito!',
          userName + ' eliminado correctamente',
          'success'
        )
      }
    })
  }

}
