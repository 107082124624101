import { Component, Renderer2, ViewChild, ElementRef, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-employee-medical-record',
  templateUrl: './employee-medical-record.component.html',
  styleUrls: ['./employee-medical-record.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class EmployeeMedicalRecordComponent implements OnInit {

  files: File[] = [];
  @ViewChild('disease', {static:false}) disease: ElementRef;
  @ViewChild('allergy', {static:false}) allergy: ElementRef;

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
  }

  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }


  addDisease() {
    const disease: HTMLDivElement = this.renderer.createElement('disease');
    disease.innerHTML = `
    <div class="margin">
        <input type="text" name="">
    </div>
`
    this.renderer.appendChild(this.disease.nativeElement, disease)
  }

  addAllergy() {
    const allergy: HTMLDivElement = this.renderer.createElement('allergy');
    allergy.innerHTML = `
    <div class="margin">
        <input type="text" name="">
    </div>
`
    this.renderer.appendChild(this.allergy.nativeElement, allergy)
  }


  save() {
    Swal.fire(
      '¡Éxito!',
      'Se ha guardado correctamente',
      'success'
    )
  }
}
