import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-configuration-role',
  templateUrl: './configuration-role.component.html',
  styleUrls: ['./configuration-role.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class ConfigurationRoleComponent implements OnInit {

  data: FormGroup;

  allData = [
    {name: "Administrador", description: "Lorem ipsum dolor sit amet consectetur adipiscing, elit dapibus faucibus odio nisl duis, ullamcorper ac nam placerat senectus." },
    {name: "Editor", description: "Lorem ipsum dolor sit amet consectetur adipiscing, elit dapibus faucibus odio nisl duis, ullamcorper ac nam placerat senectus." },
    {name: "Supervisor", description: "Lorem ipsum dolor sit amet consectetur adipiscing, elit dapibus faucibus odio nisl duis, ullamcorper ac nam placerat senectus." },
    {name: "Rol 4", description: "Lorem ipsum dolor sit amet consectetur adipiscing, elit dapibus faucibus odio nisl duis, ullamcorper ac nam placerat senectus." },
    {name: "Rol 5", description: "Lorem ipsum dolor sit amet consectetur adipiscing, elit dapibus faucibus odio nisl duis, ullamcorper ac nam placerat senectus." },
    {name: "Rol 6", description: "Lorem ipsum dolor sit amet consectetur adipiscing, elit dapibus faucibus odio nisl duis, ullamcorper ac nam placerat senectus." },
    {name: "Rol 7", description: "Lorem ipsum dolor sit amet consectetur adipiscing, elit dapibus faucibus odio nisl duis, ullamcorper ac nam placerat senectus." },
    {name: "Rol 8", description: "Lorem ipsum dolor sit amet consectetur adipiscing, elit dapibus faucibus odio nisl duis, ullamcorper ac nam placerat senectus." },
    {name: "Rol 9", description: "Lorem ipsum dolor sit amet consectetur adipiscing, elit dapibus faucibus odio nisl duis, ullamcorper ac nam placerat senectus." },
    {name: "Rol 10", description: "Lorem ipsum dolor sit amet consectetur adipiscing, elit dapibus faucibus odio nisl duis, ullamcorper ac nam placerat senectus." },
    {name: "Rol 11", description: "Lorem ipsum dolor sit amet consectetur adipiscing, elit dapibus faucibus odio nisl duis, ullamcorper ac nam placerat senectus." },
    {name: "Rol 12", description: "Lorem ipsum dolor sit amet consectetur adipiscing, elit dapibus faucibus odio nisl duis, ullamcorper ac nam placerat senectus." },
];

  constructor(private modalService: NgbModal, private fb:FormBuilder, private router: Router){
    for(let i=1;i<=10;i++){
      this.allData.push();
    }
  }

  ngOnInit() {
    this.data = this.fb.group({
      name: [''],
      description: ['']
    });
  }


    // Modal EDIT and NEW
  openModalNew(targetModal) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }


  onSubmit() {
    this.modalService.dismissAll();
    console.log("res:", this.data.getRawValue());
    Swal.fire({
      title: 'Se ha creado correctamente',
      icon: 'success',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.router.navigate(['/configuration/role/permissions']);
      }
    });
  }


// DELETE
delete(userName) {
  Swal.fire({
    title: '¿Esta seguro que desea eliminar este Rol?',
    text: 'Al hacerlo, todos los usuarios asociados al mismo, perderán sus permisos.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, eliminar'
  }).then((result) => {
    if (result.value) {
      Swal.fire(
        '¡Éxito!',
        userName + ' eliminado correctamente',
        'success'
      )
    }
  })
}



}
