import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-company-absence',
  templateUrl: './company-absence.component.html',
  styleUrls: ['./company-absence.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class CompanyAbsenceComponent implements OnInit {

  openFilter = false;

  allData =  [
    { 
      name: "Jonatan Jaime", 
      type: "Tipo 1", 
      title: "Lesión en mano derecha", 
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? ", 
      pre_application: { "year": 2021, "month": 3, "day": 7 }, 
      start_date: { "year": 2021, "month": 3, "day": 7 }, 
      end_date: { "year": 2024, "month": 3, "day": 7 }, 
      notification_users: ["emma@gmail.com","joaco@gmail.com", "jjaime@gmail.com"], 
      notification_previous_alert: 3, 
      notification_status: "true",
      justify: "true",
    },
    { 
      name: "Jonatan Jaime", 
      type: "Tipo 2", 
      title: "Lesión en mano derecha", 
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? ", 
      pre_application: { "year": 2021, "month": 3, "day": 7 }, 
      start_date: { "year": 2021, "month": 3, "day": 7 }, 
      end_date: { "year": 2024, "month": 3, "day": 7 }, 
      notification_users: ["emma@gmail.com","joaco@gmail.com", "jjaime@gmail.com"], 
      notification_previous_alert: 3, 
      notification_status: "true",
      justify: "true",
    },
    { 
      name: "Jonatan Jaime", 
      type: "Tipo 4", 
      title: "Lesión en mano derecha", 
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? ", 
      pre_application: { "year": 2021, "month": 3, "day": 7 }, 
      start_date: { "year": 2021, "month": 3, "day": 7 }, 
      end_date: { "year": 2024, "month": 3, "day": 7 }, 
      notification_users: ["emma@gmail.com","joaco@gmail.com", "jjaime@gmail.com"], 
      notification_previous_alert: 3, 
      notification_status: "true",
      justify: "true",
    },
    { 
      name: "Jonatan Jaime", 
      type: "Tipo 3", 
      title: "Lesión en mano derecha", 
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? ", 
      pre_application: { "year": 2021, "month": 3, "day": 7 }, 
      start_date: { "year": 2021, "month": 3, "day": 7 }, 
      end_date: '', 
      notification_users: [], 
      notification_previous_alert: "", 
      notification_status: "true",
      justify: "true",
    },


  ];

  items = [
    {id: 1, name: 'Jonatan J', email: 'jjaime@gmail.com'},
    {id: 2, name: 'Emmanuel P', email: 'emma@gmail.com'},
    {id: 3, name: 'Joaquin B', email: 'joaco@gmail.com'},
    {id: 4, name: 'Edgar R', email: 'agdsa@gmail.com'},
  ];

  filters_type : any[] = [
    { name: 'Tipo 1' },
    { name: 'Tipo 2' },
    { name: 'Tipo 3' },
    { name: 'Tipo 4' }
  ];
  
  justify : any[] = [
    { name: 'Todos' },
    { name: 'Justificados' },
    { name: 'No Justificados' }
  ];
  
  submitType: string;
  submitTypeEditValue: string;
  
  data: FormGroup;
  dataFilter: FormGroup;
  data_type: FormGroup;
  enableTitle = false;
  submitted = false;
  currentDate = new Date();
  files: File[] = [];
  private modalRef;
  selectedType = null;

  // Modal Alert
  private modalRefAlert;
  data_alert: FormGroup;
  
  // DISABLED ON EDIT
  disabled_on_edit:boolean = true;
  
  constructor(private fb: FormBuilder, private modalService: NgbModal, private cdRef:ChangeDetectorRef){
    for(let i=1;i<=10;i++){
      this.allData.push();
    }
  }
  
  ngOnInit() {
    this.initForm();
    this.initFormAlert();
    this.initFormFilters();
  }

  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }
  

  initForm() {
    this.data = this.fb.group({
      type:[null, Validators.required],
      title:[''],
      description:[''],
      pre_application:[''],
      days_requested:[''],
      start_date:[''],
      end_date:[''],
      justify:[''],
      subject_files:['']
    });
  }

  initFormFilters() {
    this.dataFilter = this.fb.group({
      type:[null],
      start_date:[null],
      end_date:[null],
      employee:[null],
      justify:[null],
    });
  }



  /// FORM ALERT
  openModalAlertEdit(targetModal, data_alert) {
    this.modalRefAlert = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });

    this.data_alert.patchValue({
      notification_users: data_alert.notification_users,
      notification_previous_alert: data_alert.notification_previous_alert,
      notification_status: data_alert.notification_status
    });
  }

  initFormAlert() {
    this.data_alert = this.fb.group({
      notification_users: [null, Validators.required],
      notification_previous_alert: ['', [Validators.required, Validators.min(1)]],
      notification_status: ['']
    });
  }

  modalDismissAlert() {
    this.modalRefAlert.close();  
  }

  get fna() { return this.data_alert.controls; }
   
  onSubmitAlert() {

    this.submitted = true;

    if (this.data_alert.invalid) {
        return;
    }

    console.log("res:", this.data_alert.getRawValue());
    this.modalRefAlert.close();
    this.cdRef.detectChanges();
  }



  /// FILTERS
  openFilters() {
    if(this.openFilter == true) {
      this.openFilter = false
    }else {
      this.openFilter = true
    }
  }

  onSubmitFilters() {

    this.submitted = true;

    if (this.dataFilter.invalid) {
        return;
    }

    Swal.fire(
      '¡Éxito!',
      'Tus cambios se guardaron correctamente',
      'success'
    )
    console.log("res:", this.dataFilter.getRawValue());
    
  }
}