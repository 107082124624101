import { Component, Renderer2, ViewChild, ElementRef, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-client-objective-schedule',
  templateUrl: './client-objective-schedule.component.html',
  styleUrls: ['./client-objective-schedule.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class ClientObjectiveScheduleComponent implements OnInit  {
 
  @ViewChild('monday', {static:false}) monday: ElementRef;
  @ViewChild('tuesday', {static:false}) tuesday: ElementRef;
  @ViewChild('wednesday', {static:false}) wednesday: ElementRef;
  @ViewChild('thursday', {static:false}) thursday: ElementRef;
  @ViewChild('friday', {static:false}) friday: ElementRef;
  @ViewChild('saturday', {static:false}) saturday: ElementRef;
  @ViewChild('sunday', {static:false}) sunday: ElementRef;

  innerHTML = `
  <div class="row align-center">
    <div class="col-md-4">
        <div class="wrapper__container__item time">
            <input type="number" name="" placeholder="HH"> <span>:</span> <input type="number" name="" placeholder="MM">
        </div>
    </div>
    <div class="col-md-4">
        <div class="wrapper__container__item time">
            <input type="number" name="" placeholder="HH"> <span>:</span> <input type="number" name="" placeholder="MM">
        </div>
    </div>
    <div class="col-md-3">
        <div class="wrapper__container__item">
            <input type="number" name="">
        </div>
    </div>
    <div class="col-md-1">
        <div class="wrapper__container__item">
            <button class="btn__small delete" (click)="openModalDelete(modalDelete)"><i class="icon icon-trash-2"></i></button>
        </div>
    </div>
</div>
`;

  constructor(private renderer: Renderer2, private modalService: NgbModal) { }

  ngOnInit() {
  }

  addTimeMonday() {
    const m: HTMLDivElement = this.renderer.createElement( 'monday' );
    m.innerHTML = this.innerHTML;
    this.renderer.appendChild(this.monday.nativeElement, m)
  }

  addTimeTuesday() {
    const m: HTMLDivElement = this.renderer.createElement( 'tuesday' );
    m.innerHTML = this.innerHTML;
    this.renderer.appendChild(this.tuesday.nativeElement, m)
  }
  addTimeWednesday() {
    const m: HTMLDivElement = this.renderer.createElement( 'wednesday' );
    m.innerHTML = this.innerHTML;
    this.renderer.appendChild(this.wednesday.nativeElement, m)
  }
  addTimeThursday() {
    const m: HTMLDivElement = this.renderer.createElement( 'thursday' );
    m.innerHTML = this.innerHTML;
    this.renderer.appendChild(this.thursday.nativeElement, m)
  }
  addTimeFriday() {
    const m: HTMLDivElement = this.renderer.createElement( 'friday' );
    m.innerHTML = this.innerHTML;
    this.renderer.appendChild(this.friday.nativeElement, m)
  }
  addTimeSaturday() {
    const m: HTMLDivElement = this.renderer.createElement( 'saturday' );
    m.innerHTML = this.innerHTML;
    this.renderer.appendChild(this.saturday.nativeElement, m)
  }
  addTimeSunday() {
    const m: HTMLDivElement = this.renderer.createElement( 'sunday' );
    m.innerHTML = this.innerHTML;
    this.renderer.appendChild(this.sunday.nativeElement, m)
  }


    // Modal DELETE
    
    openModalDelete(targetModal) {
      this.modalService.open(targetModal, {
        centered: true,
        backdrop: 'static'
      });    
  }

  deleteModal() {
    this.modalService.dismissAll();
    console.log("Quiere eliminar");
  }

  cancel() {
    this.modalService.dismissAll();
  }


  allData = [
    {contact: "Jonatan Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Pedro Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Juan Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Pepito Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Fran Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Jonatan Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Pedro Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Juan Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Pepito Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Fran Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Jonatan Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Pedro Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Juan Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Pepito Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Fran Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
  ];
}
