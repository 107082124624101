import { Injectable } from '@angular/core';

export const darkTheme = {
    'primary-color':'#2962ff',
    'primary-color-hover':'#214eca',
    'new-color':'#13c273',
    'red-color':'#b91e1e',
    'disabled-color': 'rgba(255,255,255,0.25)',
    'purple-color':'#6200EA',
    'teal-color':'#26A69A',
    'body-color':'#1c1e2a',
    'wrapper-color':'#313440',
    'modal-color': '#313440',
    'input-color':'#3d404c',
    'placeholder-color':'rgba(255,255,255,0.5)',
    'white-color':'#fff',
    'text-color':'#fff',
    'nav-color':'#2b2e44',
    'hover-color':'rgba(255, 255, 255, 0.15)',
    'hover-color-dark':'rgba(0, 0, 0, 0.15)',
    'btn-color':'rgba(255, 255, 255, 0.5)',
    'opacity':'0.5',
    'return-color':'#464953',
    'dropzone':'rgba(255,255,255,0.15)',
    'cropper-overlay-color':'transparent',
    'cropper-outline-color':'transparent',
};

export const lightTheme = {
    'primary-color':'#0052cc',
    'primary-color-hover':'#0d52b9',
    'new-color':'#57d9a3',
    'red-color':'#de350b',
    'disabled-color': 'rgba(0,0,0,0.25)',
    'purple-color':'#6200EA',
    'teal-color':'#26A69A',
    'body-color':'#f4f5f7',
    'wrapper-color':'#FFFFFF',
    'modal-color': '#fff',
    'input-color':'#f4f5f7',
    'placeholder-color':'#B8C8CF',
    'white-color':'#37474F',
    'text-color':'#505f79',
    'nav-color':'#fff',
    'hover-color':'rgba(0, 0, 0, 0.15)',
    'hover-color-dark':'rgba(0, 0, 0, 0.05)',
    'btn-color':'#8C8C8C',
    'opacity':'0.8',
    'return-color':'rgba(0, 0, 0, 0.2)',
    'dropzone':'rgba(0,0,0,0.15)',
    'cropper-overlay-color':'transparent',
    'cropper-outline-color':'transparent',
};

@Injectable({ providedIn: 'root' })
export class ThemeService {
  toggleDark() {
    this.setTheme(darkTheme);
  }

  toggleLight() {
    this.setTheme(lightTheme);
  }

  private setTheme(theme: {}) {
    Object.keys(theme).forEach(k =>
      document.documentElement.style.setProperty(`--${k}`, theme[k])
    );
  }
}
