import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormGroup, FormBuilder  } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-configuration-role-permissions',
  templateUrl: './configuration-role-permissions.component.html',
  styleUrls: ['./configuration-role-permissions.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class ConfigurationRolePermissionsComponent implements OnInit {

  data: FormGroup;
  hasActive: boolean = false;

  allData = [
    { permission: "Companía", access: true, edit: true,
      subpermission: [
        {
          name: "Banco",
          access: true,
          edit: true
        },
        {
          name: "Sucursales",
          access: true,
          edit: false
        },
        {
          name: "Ausencias",
          access: false,
          edit: false
        },
        {
          name: "Sanciones",
          access: false,
          edit: true
        },
        {
          name: "Seguros",
          access: false,
          edit: true
        },
        {
          name: "Medicina Laboral",
          access: false,
          edit: true
        },
        {
          name: "Documentos",
          access: false,
          edit: true
        },
        {
          name: "Habilitaciones",
          access: false,
          edit: true
        }
      ]
    },
    { permission: "Personal", access: true, edit: false,
      subpermission: [
        {
          name: "Detalle",
          access: true,
          edit: true
        },
        {
          name: "Contrato",
          access: true,
          edit: false
        },
        {
          name: "Sanciones",
          access: true,
          edit: false
        },
        {
          name: "Documentos",
          access: true,
          edit: false
        }
      ]
    },
    { permission: "Página 3", access: false, edit: true },
    { permission: "Página 4", access: false, edit: true },
    { permission: "Página 5", access: true, edit: false },
    { permission: "Página 6", access: true, edit: false },
    { permission: "Página 7", access: false, edit: true },
    { permission: "Página 8", access: false, edit: false },
    { permission: "Página 9", access: true, edit: true },
    { permission: "Página 10", access: true, edit: true },
    { permission: "Página 11", access: false, edit: false },
    { permission: "Página 12", access: true, edit: true },
  ];



  constructor(private modalService: NgbModal, private fb: FormBuilder, private router: Router) { }

  ngOnInit() {
    this.data = this.fb.group({
      permission: [''],
      access: [''],
      edit: ['']
    });
  }


  public toggle( element: HTMLElement, icon: HTMLElement ) {
    element.classList.toggle('d-none');

    if(icon.classList.contains('icon-chevron-right') === true) {
        icon.classList.remove('icon-chevron-right');
        icon.classList.add('icon-chevron-down');
    }else {
      icon.classList.add('icon-chevron-right');
      icon.classList.remove('icon-chevron-down');
    }
  }

  changeSubpermissionAccess(subpermission, i, father) {
    subpermission[i].access = !subpermission[i].access;
    if(subpermission[i].access === true) {
      father.access = true;
    }else {
      subpermission[i].edit = false;
    }
  }

  changeSubpermissionEdit(subpermission, i, father) {
    if(subpermission[i].access === true) {
      subpermission[i].edit = !subpermission[i].edit;
    }
    if(subpermission[i].edit === true) {
      father.edit = true;
    }
  }

  changePermissionAccess(data) {
    data.access = !data.access;

    for (var i = 0; i < data.subpermission.length; i++) {
      data.subpermission[i].access = data.access;
    }
  }



  changePermissionEdit(data) {
    data.edit = !data.edit;

    for (var i = 0; i < data.subpermission.length; i++) {
      data.subpermission[i].edit = data.edit;
    }
  }

  save() {
    Swal.fire(
      '¡Éxito!',
      'Se ha guardado correctamente',
      'success'
    )
  }


}
