import { Component, Inject } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';

import { ThemeService } from './theme.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent {

  themeDark: boolean = true;

  constructor(
    @Inject(LOCAL_STORAGE)private storage: StorageService, 
    private themeService: ThemeService,
    private config: NgSelectConfig) {
      this.config.notFoundText = 'No se han encontrado resultados';

      if(!this.storage.get('theme-dark')){
        this.storage.set('theme-dark', 'true');
        this.themeService.toggleDark();
        this.themeDark = true;
      }else {
        this.validateThemeColor();
      }
  }

  validateThemeColor() {
    if (this.storage.get('theme-dark') == 'true'){
      this.storage.set('theme-dark', 'true');
      this.themeService.toggleDark();
      this.themeDark = true;
    } else {
      this.storage.set('theme-dark', 'false');
      this.themeService.toggleLight();
      this.themeDark = false;
    }
  }

}


