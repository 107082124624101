import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class UserNotificationsComponent implements OnInit {

  data: FormGroup;
  notifications = [
    {
      name: "Auditoria de elementos",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      email: true,
      visual: false,
      audible: true,
    },
    {
      name: "Auditoria de uniformes",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      email: true,
      visual: true,
      audible: true,
    },
    {
      name: "Botón de pánico",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      email: true,
      visual: true,
      audible: true,
    },
    {
      name: "Caída de equipos",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      email: true,
      visual: false,
      audible: false,
    },
    {
      name: "Cierre de sesión forzado",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      email: false,
      visual: false,
      audible: true,
    },
    {
      name: "Botón de pánico",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      email: true,
      visual: true,
      audible: true,
    },
    {
      name: "Caída de equipos",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      email: true,
      visual: false,
      audible: false,
    },
    {
      name: "Cierre de sesión forzado",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      email: false,
      visual: false,
      audible: true,
    },
    {
      name: "Auditoria de elementos",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      email: true,
      visual: false,
      audible: true,
    },
    {
      name: "Auditoria de uniformes",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      email: true,
      visual: true,
      audible: true,
    },
  ]

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.initForm();
  }
  
  initForm() {
    this.data = this.fb.group({
      email: [''],
      visual: [''],
      audible: [''],
    });
  }

  get f() { return this.data.controls; }
   
  onSubmit() {
    if (this.data.invalid) {
        return;
    }

    Swal.fire(
      '¡Éxito!',
      'Cambios actualizados correctamente',
      'success'
    )

    console.log("res:", this.data.getRawValue());
  }

}
