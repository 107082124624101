import { Component, OnInit } from '@angular/core';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-client-contacts',
  templateUrl: './client-contacts.component.html',
})
export class ClientContactsComponent implements OnInit {

  data: FormGroup;

  files: File[] = [];

  allData = [
    {contact: "Jonatan Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Pedro Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Juan Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Pepito Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Fran Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Jonatan Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Pedro Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Juan Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Pepito Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Fran Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Jonatan Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Pedro Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Juan Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Pepito Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {contact: "Fran Jaime", position: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
  ];
  
  constructor(private fb: FormBuilder, private modalService: NgbModal){
    for(let i=1;i<=10;i++){
      this.allData.push();
    }
  }
  
  ngOnInit() {
    this.data = this.fb.group({
      contact: [''],
      position: [''],
      telephone: [''],
      phone: [''],
      email: [''],
      skype: ['']
    });
   }

   // Modal EDIT and NEW
  openModalEdit(targetModal, data) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
   
    this.data.patchValue({
      contact: data.contact,
      position: data.position,
      telephone: data.telephone,
      phone: data.phone,
      email: data.email,
      skype: data.skype
    });
  }

  openModalNew(targetModal) {
    this.modalService.open(targetModal, {
     centered: true,
     backdrop: 'static'
    });    
  }

   
  onSubmit() {
    this.modalService.dismissAll();
    console.log("res:", this.data.getRawValue());
    Swal.fire(
      '¡Éxito!',
      'Se ha guardado correctamente',
      'success'
    )
  }


  // Modal DELETE
    
  openModalDelete(targetModal) {
      this.modalService.open(targetModal, {
        centered: true,
        backdrop: 'static'
      });    
  }

  deleteModal() {
    this.modalService.dismissAll();
    console.log("Quiere eliminar");
  }

  cancel() {
    this.modalService.dismissAll();
  }


  //Files Dropzone

  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }
   
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  delete() {
    Swal.fire({
      title: '¿Estas seguro de eliminar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          '¡Éxito!',
          'Contacto eliminado correctamente',
          'success'
        )
      }
    })
  }

}
