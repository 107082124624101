import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';
import Swal from 'sweetalert2'
import { CreditCardValidators } from 'angular-cc-library';

@Component({
  selector: 'app-user-payment-methods',
  templateUrl: './user-payment-methods.component.html',
  styleUrls: ['./user-payment-methods.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})



export class UserPaymentMethodsComponent implements OnInit {

  data: FormGroup;
  allData = [
    {
      logo: "mastercard",
      method: "Método 2",
      name: "Emmanuel",
      last_name: "Perretta",
      phone: "123321123",
      email: "correo@correo.com",
      creditCard: "1234123412345814",
      expirationDate: "12/24",
      cvc: "123",
      predetermined: true,
    },
    {
      logo: "visa",
      method: "Método 1",
      name: "Emmanuel",
      last_name: "Perretta",
      phone: "123321123",
      email: "correo@correo.com",
      creditCard: "1234123412345814",
      expirationDate: "12/24",
      cvc: "123",
      predetermined: false,
    },
    {
      logo: "mastercard",
      method: "Método 2",
      name: "Emmanuel",
      last_name: "Perretta",
      phone: "123321123",
      email: "correo@correo.com",
      creditCard: "1234123412345814",
      expirationDate: "12/24",
      cvc: "123",
      predetermined: false,
    },
  ];

  // modal type
  private modalRef;
  selectedType = null;
  submitType: string;
  submitTypeEditValue: string;
  data_type: FormGroup;
  submitted:boolean = false;
  disabled_on_edit:boolean = false;
  items_type : any[] = [
    { name: 'Agregar nuevo' },
    { name: 'Método 1' },
    { name: 'Método 2' },
    { name: 'Método 3' }
  ];
  // end modal type

  constructor(private fb: FormBuilder, private modalService: NgbModal, private cdRef:ChangeDetectorRef) { }

  ngOnInit() {
    this.initForm();
    this.initFormType();
  }

  initForm() {
    this.data = this.fb.group({
      method: [null, Validators.required],
      name: ['', Validators.required],
      last_name: [''],
      phone: [''],
      email: [''],
      creditCard: ['', [CreditCardValidators.validateCCNumber]],
      expirationDate: ['', [CreditCardValidators.validateExpDate]],
      cvc: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
      predetermined: [''],
    });
  }

   // Modal EDIT and NEW
  openModalEdit(targetModal, data) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
   
    this.data.patchValue({
      method: data.method,
      name: data.name,
      last_name: data.last_name,
      phone: data.phone,
      email: data.email,
      creditCard: data.creditCard,
      expirationDate: data.expirationDate,
      cvc: data.cvc,
      predetermined: data.predetermined,
    });
  }

  openModalNew(targetModal) {
    this.disabled_on_edit = false;
    this.initForm();
    this.cdRef.detectChanges();

    this.modalService.open(targetModal, {
     centered: true,
     backdrop: 'static'
    });    

  }

  get f() { return this.data.controls; }
   
  onSubmit() {

    this.submitted = true;

    if (this.data.invalid) {
        return;
    }

    Swal.fire(
      '¡Éxito!',
      'Tus cambios se guardaron correctamente',
      'success'
    )
    this.modalService.dismissAll();
    console.log("res:", this.data.getRawValue());
  }

  //  DELETE 
  delete(predetermined) {
    if(predetermined) {
      Swal.fire(
        'No se puede eliminar',
        'Elija otro metodo como predeterminado para poder hacerlo',
        'warning'
      )
    }else {
      Swal.fire({
        title: '¿Eliminar medio de pago?',
        // text: "Al eliminarno perderás los datos",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar'
      }).then((result) => {
        if (result.value) {
          Swal.fire(
            '¡Éxito!',
            'Medio de pago eliminado con éxito',
            'success'
          )
        }
      })
    }
  }







  /// FORM TYPES
  openModalTypeEdit(targetModal, data_type) {
    this.modalRef = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
   
    this.submitType = 'edit';
    this.submitTypeEditValue = data_type.name;

    this.data_type.patchValue({
      name: data_type.name,
    });
  }

  initFormType() {
    this.data_type = this.fb.group({
      name:['', Validators.required],
    });
  }

  openModalTypeNew(targetModal) {
    this.initFormType();
    this.selectedType = [];
    this.cdRef.detectChanges();

    this.submitType = 'new';

    this.modalRef = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  modalDismiss() {
    this.selectedType = [];
    this.modalRef.close();  
  }

  get fn() { return this.data_type.controls; }
   
  onSubmitType() {

    this.submitted = true;

    if (this.data_type.invalid) {
        return;
    }

    if(this.submitType == 'edit') {
      this.items_type.find(item => item.name == this.submitTypeEditValue).name =  this.data_type.value.name;
      this.items_type = [...this.items_type];
      this.data.get('method').setValue(this.data_type.value.name)
    }

    if(this.submitType == 'new') {
      this.items_type = [...this.items_type, this.data_type.getRawValue()];
      this.data.get('method').setValue(this.data_type.value.name)
    }

    this.modalRef.close();
    this.cdRef.detectChanges();
  }

  deleteType(index) {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {

        this.items_type.splice(index,1);
        this.items_type = [...this.items_type];

        this.selectedType = [];

        this.cdRef.detectChanges();
        
        console.log(this.items_type);

        Swal.fire(
          '¡Éxito!',
          'Eliminado correctamente',
          'success'
        )
      }
    })
  }

}
