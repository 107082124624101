import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class DashboardComponent implements OnInit {

  data: FormGroup;

  files: File[] = [];

  id_company = 0;

  allData = [
    {
      id: "1",
      name: "Jonatan", 
      telephone: "123456789", 
      approved: "true", 
      phone: "6543216543", 
      fantasy_name: "Hola don pepito", 
      password: "12345", 
      contact_name: "Nombre",
      user: "jjaime",
      address: "Pellegrini 123, Rosario, Santa Fe, Argentina.",
      cbu: "1234513642737",
      cta: "t3rtre321321",
      cart_type: "Tipo de tarjeta (?)",
      cart_fullname: "Jonatan Jaime", 
      cart_number: "321321321432143214",
      cart_code: "12312",
      cart_expire: "12/20"
    },
    {
      id: "2",
      name: "Nahuel", 
      telephone: "123456789", 
      approved: "true", 
      phone: "6543216543", 
      fantasy_name: "Hola don pepito", 
      password: "12345", 
      contact_name: "Nombre",
      user: "jjaime",
      address: "Pellegrini 123, Rosario, Santa Fe, Argentina.",
      cbu: "1234513642737",
      cta: "t3rtre321321",
      cart_type: "Tipo de tarjeta (?)",
      cart_fullname: "Jonatan Jaime", 
      cart_number: "321321321432143214",
      cart_code: "12312",
      cart_expire: "12/20"
    },
    {
      id: "3",
      name: "Pepito", 
      telephone: "123456789", 
      approved: "true", 
      phone: "6543216543", 
      fantasy_name: "Hola don pepito", 
      password: "12345", 
      contact_name: "Nombre",
      user: "jjaime",
      address: "Pellegrini 123, Rosario, Santa Fe, Argentina.",
      cbu: "1234513642737",
      cta: "t3rtre321321",
      cart_type: "Tipo de tarjeta (?)",
      cart_fullname: "Jonatan Jaime", 
      cart_number: "321321321432143214",
      cart_code: "12312",
      cart_expire: "12/20"
    },
    {
      id: "4",
      name: "Brad", 
      telephone: "123456789", 
      approved: "true", 
      phone: "6543216543", 
      fantasy_name: "Hola don pepito", 
      password: "12345", 
      contact_name: "Nombre",
      user: "jjaime",
      address: "Pellegrini 123, Rosario, Santa Fe, Argentina.",
      cbu: "1234513642737",
      cta: "t3rtre321321",
      cart_type: "Tipo de tarjeta (?)",
      cart_fullname: "Jonatan Jaime", 
      cart_number: "321321321432143214",
      cart_code: "12312",
      cart_expire: "12/20"
    },
    {
      id: "5",
      name: "Macro", 
      telephone: "123456789", 
      approved: "true", 
      phone: "6543216543", 
      fantasy_name: "Hola don pepito", 
      email:"jjaime@gmail.com",
      password: "12345", 
      contact_name: "Nombre",
      user: "jjaime",
      address: "Pellegrini 123, Rosario, Santa Fe, Argentina.",
      cbu: "1234513642737",
      cta: "t3rtre321321",
      cart_type: "Tipo de tarjeta (?)",
      cart_fullname: "Jonatan Jaime", 
      cart_number: "321321321432143214",
      cart_code: "12312",
      cart_expire: "12/20"
    },
    {
      id: "6",
      name: "La segunda", 
      telephone: "123456789", 
      approved: "true", 
      phone: "6543216543", 
      fantasy_name: "Hola don pepito", 
      password: "12345", 
      contact_name: "Nombre",
      user: "jjaime",
      address: "Pellegrini 123, Rosario, Santa Fe, Argentina.",
      cbu: "1234513642737",
      cta: "t3rtre321321",
      cart_type: "Tipo de tarjeta (?)",
      cart_fullname: "Jonatan Jaime", 
      cart_number: "321321321432143214",
      cart_code: "12312",
      cart_expire: "12/20"
    },
    {
      id: "7",
      name: "San Cristobal", 
      telephone: "123456789", 
      approved: "true", 
      phone: "6543216543", 
      fantasy_name: "Hola don pepito", 
      password: "12345", 
      contact_name: "Nombre",
      user: "jjaime",
      address: "Pellegrini 123, Rosario, Santa Fe, Argentina.",
      cbu: "1234513642737",
      cta: "t3rtre321321",
      cart_type: "Tipo de tarjeta (?)",
      cart_fullname: "Jonatan Jaime", 
      cart_number: "321321321432143214",
      cart_code: "12312",
      cart_expire: "12/20"
    },
    {
      id: "8",
      name: "Julia", 
      telephone: "123456789", 
      approved: "true", 
      phone: "6543216543", 
      fantasy_name: "Hola don pepito", 
      password: "12345", 
      contact_name: "Nombre",
      user: "jjaime",
      address: "Pellegrini 123, Rosario, Santa Fe, Argentina.",
      cbu: "1234513642737",
      cta: "t3rtre321321",
      cart_type: "Tipo de tarjeta (?)",
      cart_fullname: "Jonatan Jaime", 
      cart_number: "321321321432143214",
      cart_code: "12312",
      cart_expire: "12/20"
    },
    {
      id: "9",
      name: "Yerik", 
      telephone: "123456789", 
      approved: "true", 
      phone: "6543216543", 
      fantasy_name: "Hola don pepito", 
      password: "12345", 
      contact_name: "Nombre",
      user: "jjaime",
      address: "Pellegrini 123, Rosario, Santa Fe, Argentina.",
      cbu: "1234513642737",
      cta: "t3rtre321321",
      cart_type: "Tipo de tarjeta (?)",
      cart_fullname: "Jonatan Jaime", 
      cart_number: "321321321432143214",
      cart_code: "12312",
      cart_expire: "12/20"
    },
    {
      id: "10",
      name: "Joaquin", 
      telephone: "123456789", 
      approved: "true", 
      phone: "6543216543", 
      fantasy_name: "Hola don pepito", 
      email:"jjaime@gmail.com",
      password: "12345", 
      contact_name: "Nombre",
      user: "jjaime",
      address: "Pellegrini 123, Rosario, Santa Fe, Argentina.",
      cbu: "1234513642737",
      cta: "t3rtre321321",
      cart_type: "Tipo de tarjeta (?)",
      cart_fullname: "Jonatan Jaime", 
      cart_number: "321321321432143214",
      cart_code: "12312",
      cart_expire: "12/20"
    },
];

  
  
  constructor(private fb: FormBuilder, private modalService: NgbModal){
    for(let i=1;i<=10;i++){
      this.allData.push();
    }
  }
  
  ngOnInit() {
    this.data = this.fb.group({
      name: [''],
      telephone: [''],
      approved: [''],
      phone: [''],
      fantasy_name: [''],
      password: [''],
      contact_name: [''],
      user: [''],
      website: [''],
      email: [''],
      address: [''],
      cbu: [''],
      cta: [''],
      cart_type: [''],
      cart_fullname: [''],
      cart_number: [''],
      cart_code: [''],
      cart_expire: ['']
    });
   }

   // Modal EDIT and NEW
  openModalEdit(targetModal, data) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static',
      windowClass: 'modal_dashboard'
    });
   
    this.data.patchValue({
      name: data.name,
      telephone: data.telephone,
      approved: data.approved,
      phone: data.phone,
      website: data.website,
      fantasy_name: data.fantasy_name,
      password: data.password,
      contact_name: data.contact_name,
      user: data.user,
      address: data.address,
      email: data.email,
      cbu: data.cbu,
      cta: data.cta,
      cart_type: data.cart_type,
      cart_fullname: data.cart_fullname,
      cart_number: data.cart_number,
      cart_code: data.cart_code,
      cart_expire: data.cart_expire
    });
  }

  openModalNew(targetModal) {
    this.modalService.open(targetModal, {
     centered: true,
     backdrop: 'static',
     windowClass: 'modal_dashboard'
    });    
  }

   
  onSubmit() {
    Swal.fire(
      '¡Éxito!',
      'Se ha guardado correctamente',
      'success'
    )
    this.modalService.dismissAll();
    console.log("res:", this.data.getRawValue());
  }


  // Modal DELETE
    
  openModalDelete(targetModal) {
      this.modalService.open(targetModal, {
        centered: true,
        backdrop: 'static'
      });    
  }

  deleteModal() {
    this.modalService.dismissAll();
    console.log("Quiere eliminar");
  }

  cancel() {
    this.modalService.dismissAll();
  }


  //Files Dropzone

  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }
   
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }


  changeId(id) {
    console.log(id);
    this.id_company = id;
  }

   //  DELETE
   delete() {
    Swal.fire({
      title: '¿Estás seguro?',
      // text: "Al eliminarno perderás los datos",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          '¡Éxito!',
          'Empresa eliminada con éxito',
          'success'
        )
      }
    })
  }

}
