import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-provider-costs',
  templateUrl: './provider-costs.component.html',
  styleUrls: ['./provider-costs.component.scss']
})
export class ProviderCostsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
