import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-objective-elements',
  templateUrl: './client-objective-elements.component.html',
  styleUrls: ['./client-objective-elements.component.scss']
})
export class ClientObjectiveElementsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
