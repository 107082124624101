import { Component, Renderer2, ViewChild, ElementRef, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-client-objective-checkpoints-round-management-edit',
  templateUrl: './client-objective-checkpoints-round-management-edit.component.html',
  styleUrls: ['./client-objective-checkpoints-round-management-edit.component.scss']
})
export class ClientObjectiveCheckpointsRoundManagementEditComponent implements OnInit {

  @ViewChild('hour', {static:false}) hour: ElementRef;
  programming:string = 'aleatoria';
  programming_enabled:boolean = false;

  day_active:number = -1;
  points:boolean = false;

  semanas = [
    { day: "Domingo", 
      shifts: [
        {
          shift: '00:00',
          data: [
            {
              hour: '02:00'
            },
            {
              hour: '04:00'
            },
            {
              hour: '07:00'
            }
          ]
        },
        {
          shift: '00:00',
          data: [
            {
              hour: '02:00'
            },
            {
              hour: '04:00'
            },
            {
              hour: '07:00'
            }
          ]
        },
        {
          shift: '00:00',
          data: [
            {
              hour: '02:00'
            },
            {
              hour: '04:00'
            },
            {
              hour: '07:00'
            }
          ]
        },
      ]
    },
    { day: "Lunes"},
    { day: "Martes"},
    { day: "Miercoles"},
    { day: "Jueves"},
    { day: "Viernes"},
    { day: "Sábado"}
  ];

  checkpoints = [
    {
      title: "Checkpoint 1", 
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? Est accusantium neque quisquam voluptas at? Nam vitae minima odit fugiat explicabo.",
      position: 'Interior',
      qr: 'Aleatorio'
    },
    {
      title: "Checkpoint 2", 
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? Est accusantium neque quisquam voluptas at? Nam vitae minima odit fugiat explicabo.",
      position: 'Exterior',
      qr: 'Aleatorio'
    },
    {
      title: "Checkpoint 3", 
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? Est accusantium neque quisquam voluptas at? Nam vitae minima odit fugiat explicabo.",
      position: 'Interior',
      qr: 'Aleatorio'
    },
  ];

  assigned_checkpoints  = [
    {
      title: "Checkpoint 5", 
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? Est accusantium neque quisquam voluptas at? Nam vitae minima odit fugiat explicabo.",
      position: 'Interior',
      qr: 'Aleatorio'
    },
  ];

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
  }


  change(type) {
    console.log(type);
    this.programming = type;
  }

  editDay(data, i) {
    console.log(data, i);
    this.day_active = i;
  }

  selectProgramm() {
    this.points = false;
    this.programming_enabled = true;
  }

  selectPoints() {
    this.points = true;
    this.programming_enabled = false;
  }

  save() {
    Swal.fire(
      '¡Éxito!',
      'Se ha guardado correctamente',
      'success'
    )
  }
 

  createShiftHour(i) {
    console.log(i);
    const el = 'hour'+i;
    
    console.log(el);
    const hour: HTMLDivElement = this.renderer.createElement(el);
    hour.innerHTML = `
      <div class="wrapper__container__item">
          <input type="time" name="">
      </div>
`
    this.renderer.appendChild(this.hour.nativeElement, hour)
  }

}
