import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-provider-banks',
  templateUrl: './provider-banks.component.html',
  styleUrls: ['./provider-banks.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})

export class ProviderBanksComponent implements OnInit {

  data: FormGroup;

  allData = [
    {
      bank: "Macro", 
      account_number: "00421421", 
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti, fugit",
      alias: "Alias 1", 
      account_officer: "Jonatan Jaime",
      phone: "3462619188",
      email: "jonatan.jaime8@gmail.com",
    },
    {
      bank: "Santa Fe", 
      account_number: "00421421", 
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti, fugit",
      alias: "Alias 2", 
      account_officer: "Jonatan Jaime",
      phone: "3462619188",
      email: "jonatan.jaime8@gmail.com",
    },
    {
      bank: "Credicoop", 
      account_number: "00421421", 
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti, fugit",
      alias: "Alias 3", 
      account_officer: "Jonatan Jaime",
      phone: "3462619188",
      email: "jonatan.jaime8@gmail.com",
    },
    {
      bank: "Macro", 
      account_number: "00421421", 
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti, fugit",
      alias: "Alias 4", 
      account_officer: "Jonatan Jaime",
      phone: "3462619188",
      email: "jonatan.jaime8@gmail.com",
    },
    {
      bank: "Santa Fe", 
      account_number: "00421421", 
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti, fugit",
      alias: "Alias 5", 
      account_officer: "Jonatan Jaime",
      phone: "3462619188",
      email: "jonatan.jaime8@gmail.com",
    },
    {
      bank: "Credicoop", 
      account_number: "00421421", 
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti, fugit",
      alias: "Alias 6", 
      account_officer: "Jonatan Jaime",
      phone: "3462619188",
      email: "jonatan.jaime8@gmail.com",
    },
    {
      bank: "Macro", 
      account_number: "00421421", 
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti, fugit",
      alias: "Alias 7", 
      account_officer: "Jonatan Jaime",
      phone: "3462619188",
      email: "jonatan.jaime8@gmail.com",
    },
    {
      bank: "Santa Fe", 
      account_number: "00421421", 
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti, fugit",
      alias: "Alias 8", 
      account_officer: "Jonatan Jaime",
      phone: "3462619188",
      email: "jonatan.jaime8@gmail.com",
    },
    {
      bank: "Credicoop", 
      account_number: "00421421", 
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti, fugit", 
      alias: "Alias 9", 
      account_officer: "Jonatan Jaime",
      phone: "3462619188",
      email: "jonatan.jaime8@gmail.com",
    }
  ];
  
  // modal type
  private modalRef;
  selectedType = null;
  submitType: string;
  submitTypeEditValue: string;
  data_type: FormGroup;
  submitted:boolean = false;
  disabled_on_edit:boolean = false;
  items_type : any[] = [
    { name: 'Agregar nuevo' },
    { name: 'Macro' },
    { name: 'Santa Fe' },
    { name: 'Credicoop' }
  ];
  // end modal type

  
  constructor(private fb: FormBuilder, private modalService: NgbModal, private cdRef:ChangeDetectorRef){
    for(let i=1;i<=10;i++){
      this.allData.push();
    }
  }
  
  ngOnInit() {
    this.initForm();
    this.initFormType();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  initForm() {
    this.data = this.fb.group({
      bank: [null, Validators.required],
      account_number: ['', Validators.required],
      description: [''],
      alias: [''],
      account_officer: [''],
      phone: [''],
      email: ['']
    });
  }

   // Modal EDIT and NEW
  openModalEdit(targetModal, data) {
    
    this.disabled_on_edit = true;

    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
   
    this.data.patchValue({
      bank: data.bank,
      account_number: data.account_number,
      description: data.description,
      alias: data.alias,
      account_officer: data.account_officer,
      phone: data.phone,
      email: data.email
    });
  }

  openModalNew(targetModal) {
    this.disabled_on_edit = false;
    this.initForm();
    this.cdRef.detectChanges();

    this.modalService.open(targetModal, {
     centered: true,
     backdrop: 'static'
    });    

  }

  get f() { return this.data.controls; }
   
  onSubmit() {

    this.submitted = true;

    if (this.data.invalid) {
        return;
    }

    Swal.fire(
      '¡Éxito!',
      'Tus cambios se guardaron correctamente',
      'success'
    )
    this.modalService.dismissAll();
    console.log("res:", this.data.getRawValue());
  }

  //  DELETE 
  delete() {
    Swal.fire({
      title: '¿Estás seguro?',
      // text: "Al eliminarno perderás los datos",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          '¡Éxito!',
          'Banco eliminado con éxito',
          'success'
        )
      }
    })
  }







  /// FORM TYPES
  openModalTypeEdit(targetModal, data_type) {
    this.modalRef = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
   
    this.submitType = 'edit';
    this.submitTypeEditValue = data_type.name;

    this.data_type.patchValue({
      name: data_type.name,
    });
  }

  initFormType() {
    this.data_type = this.fb.group({
      name:['', Validators.required],
    });
  }

  openModalTypeNew(targetModal) {
    this.initFormType();
    this.selectedType = [];
    this.cdRef.detectChanges();

    this.submitType = 'new';

    this.modalRef = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  modalDismiss() {
    this.selectedType = [];
    this.modalRef.close();  
  }

  get fn() { return this.data_type.controls; }
   
  onSubmitType() {

    this.submitted = true;

    if (this.data_type.invalid) {
        return;
    }

    if(this.submitType == 'edit') {
      this.items_type.find(item => item.name == this.submitTypeEditValue).name =  this.data_type.value.name;
      this.items_type = [...this.items_type];
      this.data.get('bank').setValue(this.data_type.value.name)
    }

    if(this.submitType == 'new') {
      this.items_type = [...this.items_type, this.data_type.getRawValue()];
      this.data.get('bank').setValue(this.data_type.value.name)
    }

    this.modalRef.close();
    this.cdRef.detectChanges();
  }

  deleteType(index) {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {

        this.items_type.splice(index,1);
        this.items_type = [...this.items_type];

        this.selectedType = [];

        this.cdRef.detectChanges();
        
        console.log(this.items_type);

        Swal.fire(
          '¡Éxito!',
          'Eliminado correctamente',
          'success'
        )
      }
    })
  }

}
