import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'


@Component({
  selector: 'app-client-objective-checkpoints-round-management',
  templateUrl: './client-objective-checkpoints-round-management.component.html',
  styleUrls: ['./client-objective-checkpoints-round-management.component.scss']
})
export class ClientObjectiveCheckpointsRoundManagementComponent implements OnInit {

  id_company = 0;
  allData = [
    {
      title: "Ronda Calderas", 
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? Est accusantium neque quisquam voluptas at? Nam vitae minima odit fugiat explicabo.",
      points: '4',
      programming: 'Aleatorio',
      checkpoints: [
        {
          title: "Checkpoint 1", 
          description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? Est accusantium neque quisquam voluptas at? Nam vitae minima odit fugiat explicabo.",
          position: 'Interior',
          qr: 'Aleatorio'
        },
        {
          title: "Checkpoint 2", 
          description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? Est accusantium neque quisquam voluptas at? Nam vitae minima odit fugiat explicabo.",
          position: 'Exterior',
          qr: 'Aleatorio'
        },
        {
          title: "Checkpoint 3", 
          description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? Est accusantium neque quisquam voluptas at? Nam vitae minima odit fugiat explicabo.",
          position: 'Interior',
          qr: 'Aleatorio'
        },
      ]
    },
    {
      title: "Ronda Balcon", 
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? Est accusantium neque quisquam voluptas at? Nam vitae minima odit fugiat explicabo.",
      points: '6',
      programming: 'Preestablecida',
      checkpoints: [
        {
          title: "Checkpoint 4", 
          description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? Est accusantium neque quisquam voluptas at? Nam vitae minima odit fugiat explicabo.",
          position: 'Interior',
          qr: 'Aleatorio'
        },
        {
          title: "Checkpoint 5", 
          description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? Est accusantium neque quisquam voluptas at? Nam vitae minima odit fugiat explicabo.",
          position: 'Exterior',
          qr: 'Aleatorio'
        },
        {
          title: "Checkpoint 6", 
          description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? Est accusantium neque quisquam voluptas at? Nam vitae minima odit fugiat explicabo.",
          position: 'Interior',
          qr: 'Aleatorio'
        },
      ]
    },
    {
      title: "Ronda Lenovo", 
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? Est accusantium neque quisquam voluptas at? Nam vitae minima odit fugiat explicabo.",
      points: '15',
      programming: 'No determinada',
      checkpoints: [
        {
          title: "Checkpoint 7", 
          description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? Est accusantium neque quisquam voluptas at? Nam vitae minima odit fugiat explicabo.",
          position: 'Interior',
          qr: 'Aleatorio'
        },
        {
          title: "Checkpoint 8", 
          description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? Est accusantium neque quisquam voluptas at? Nam vitae minima odit fugiat explicabo.",
          position: 'Exterior',
          qr: 'Aleatorio'
        },
        {
          title: "Checkpoint 9", 
          description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit recusandae accusamus quasi consequuntur repudiandae iure, a atque vel? Est accusantium neque quisquam voluptas at? Nam vitae minima odit fugiat explicabo.",
          position: 'Interior',
          qr: 'Aleatorio'
        },
      ]
    },
  ];

  constructor() {
    for(let i=1;i<=10;i++){
      this.allData.push();
    }
  }

  changeId(id) {
    console.log(id);
    this.id_company = id;
  }

  downloadImage(title, i){
    var URLimg = document.getElementsByClassName('qrcode')[i].firstChild['src'];

    var a = document.createElement("a"); //Create <a>
    a.href = URLimg; //Image Base64 Goes here
    a.download = title+'.jpg'; //File name Here
    a.click(); //Downloaded file
  }

  ngOnInit() {
  }

   //  DELETE
   delete() {
    Swal.fire({
      title: '¿Estás seguro?',
      // text: "Al eliminarno perderás los datos",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          '¡Éxito!',
          'Documento eliminado con éxito',
          'success'
        )
      }
    })
  }

}
