import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-objective-rounds',
  templateUrl: './client-objective-rounds.component.html',
  styleUrls: ['./client-objective-rounds.component.scss']
})
export class ClientObjectiveRoundsComponent implements OnInit {

  round_active = 0;
  latitude: number = -32.9377492;
  longitude: number = -60.639004;
  zoom: number = 12;

  allData = [
    {
      title: "Ronda Calderas",
      personal: "Moises Jaime",
      fecha: "martes, 01 de abril 2020 - 17:59:07"
    },
    {
      title: "Ronda test 1",
      personal: "Josias Perez",
      fecha: "martes, 08 de abril 2020 - 15:59:07"
    },
    {
      title: "Ronda Pepito clavo un clavito",
      personal: "Josias Perez",
      fecha: "jueves, 06 de mayo 2020 - 15:59:07"
    }
  ];

  constructor() { }

  ngOnInit() {
  }

  changeRound(id) {
    console.log(id);
    this.round_active = id;
  }

}
