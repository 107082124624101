import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-company-medicine',
  templateUrl: './company-medicine.component.html',
  styleUrls: ['./company-medicine.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})

export class CompanyMedicineComponent implements OnInit {

  data: FormGroup;

  allData = [
    { 
      lender: "Prestador nombre", 
      branch: "Rosario", 
      account_officer: "Joaquin Bustos",
      emergy_phone: "3462619188",
      emergy_phone_two: "3462619188",
      emergy_phone_three: "3462619188",
      from:"12/12/2019",
      to:"12/12/2020",
      telephone: "3462619188", 
      phone: "3462619188", 
      email: "jonatan.jaime8@gmail.com",
      website: "https://google.com",
    },
    { 
      lender: "Prestador nombre", 
      branch: "Rosario", 
      account_officer: "Joaquin Bustos",
      emergy_phone: "3462619188",
      emergy_phone_two: "3462619188",
      emergy_phone_three: "3462619188",
      from:"12/12/2019",
      to:"12/12/2020",
      telephone: "3462619188", 
      phone: "3462619188", 
      email: "jonatan.jaime8@gmail.com",
      website: "https://google.com",
    },
    { 
      lender: "Prestador nombre", 
      branch: "Rosario", 
      account_officer: "Joaquin Bustos",
      emergy_phone: "3462619188",
      emergy_phone_two: "3462619188",
      emergy_phone_three: "3462619188",
      from:"12/12/2019",
      to:"12/12/2020",
      telephone: "3462619188", 
      phone: "3462619188", 
      email: "jonatan.jaime8@gmail.com",
      website: "https://google.com",
    },
  ];
  
  constructor(private fb: FormBuilder, private modalService: NgbModal){
    for(let i=1;i<=10;i++){
      this.allData.push();
    }
  }
  
  ngOnInit() {
    this.data = this.fb.group({
      lender: [''],
      branch: [''],
      account_officer: [''],
      emergy_phone: [''],
      emergy_phone_two: [''],
      emergy_phone_three: [''],
      from: [''],
      to: [''],
      telephone: [''],
      phone: [''],
      email: [''],
      website: [''],
    });
   }

   // Modal EDIT and NEW
  openModalEdit(targetModal, data) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
   
    this.data.patchValue({
      lender: data.lender,
      branch:  data.branch,
      account_officer: data.account_officer, 
      emergy_phone:  data.emergy_phone,
      emergy_phone_two:  data.emergy_phone_two,
      emergy_phone_three:  data.emergy_phone_three,
      from:  data.from,
      to: data.to,
      telephone: data.telephone,
      phone: data.phone,
      email: data.email,
      website:  data.website,
    });
  }

  openModalNew(targetModal) {
    this.modalService.open(targetModal, {
     centered: true,
     backdrop: 'static'
    });    
  }

   
  onSubmit() {
    this.modalService.dismissAll();
    console.log("res:", this.data.getRawValue());
    Swal.fire(
      '¡Éxito!',
      'Se ha guardado correctamente',
      'success'
    )    
  }

  // DELETE
  delete() {
    Swal.fire({
      title: '¿Estás seguro?',
      // text: "Al eliminarno perderás los datos",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          '¡Éxito!',
          'Prestador eliminado',
          'success'
        )
      }
    })
  }

}
