import { Component, OnInit } from '@angular/core';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2';
// import { AgmCoreModule } from '@agm/core';


@Component({
  selector: 'app-client-objective-checkpoints',
  templateUrl: './client-objective-checkpoints.component.html',
  styleUrls: ['./client-objective-checkpoints.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class ClientObjectiveCheckpointsComponent implements OnInit {

  data: FormGroup;
  lat:number;
  lng:number;
  zoom:number = 14;

  allData = [
    {title: "Checkpoint 1", description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad asperiores consectetur placeat", position: "Interior", lat: "-32.9475922", lng: "-60.6303854,18", qr: "nfasfsja", orden: "7",  status: "true", task: "4"},
    {title: "Checkpoint 2", description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad asperiores consectetur placeat", position: "Exterior", lat: "-33.7417893", lng: "-61.9635421421962", qr: "dsadsa", orden: "5",  status: "false", task: "8"},
    {title: "Checkpoint 45", description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad asperiores consectetur placeat", position: "Interior", lat: "-33.7417893", lng: "-61.9635962", qr: "rwevrewv", orden: "7",  status: "false", task: "5"},
    {title: "Checkpoint 421", description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad asperiores consectetur placeat", position: "Exterior", lat: "-33.7417121893", lng: "-61.9635962", qr: "cbxvbcxb", orden: "6",  status: "false", task: "2"},
    {title: "Checkpoint y42", description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad asperiores consectetur placeat", position: "Exterior", lat: "-33.7417893", lng: "-61.9635962", qr: "gfsbgsdfbgd", orden: "1",  status: "true", task: "9"},
    {title: "Checkpoint 5", description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad asperiores consectetur placeat", position: "Interior", lat: "-33.7417893", lng: "-61.9635962", qr: "gjfhjhf", orden: "3",  status: "true", task: "7"},
    {title: "Checkpoint 2", description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad asperiores consectetur placeat", position: "Interior", lat: "-33.421421", lng: "-61.9635962", qr: "1421353", orden: "5",  status: "true", task: "3"},
  ];
  
  constructor(private fb: FormBuilder, private modalService: NgbModal){
    for(let i=1;i<=10;i++){
      this.allData.push();
    }
  }

  downloadImage(title, i){
    var URLimg = document.getElementsByClassName('qrcode')[i].firstChild['src'];

    var a = document.createElement("a"); //Create <a>
    a.href = URLimg; //Image Base64 Goes here
    a.download = title+'.jpg'; //File name Here
    a.click(); //Downloaded file
  }
  
  ngOnInit() {
    this.data = this.fb.group({
      title: [''],
      description: [''],
      position: [''],
      lat: [''],
      lng: [''],
      qr: [''],
      orden: [''],
      status: ['']
    });
   }

   // Modal EDIT and NEW
  openModalEdit(targetModal, data) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
   
    this.data.patchValue({
      title: data.title,
      description: data.description,
      position: data.position,
      lat: data.lat,
      lng: data.lng,
      qr: data.qr,
      orden: data.orden,
      status: data.status
    });
  }

  openModalNew(targetModal) {
    this.modalService.open(targetModal, {
     centered: true,
     backdrop: 'static'
    });    
  }

   
  onSubmit() {
    Swal.fire(
      '¡Éxito!',
      'Se ha guardado correctamente',
      'success'
    )
    this.modalService.dismissAll();
    console.log("res:", this.data.getRawValue());
  }

    //  DELETE
    delete() {
      Swal.fire({
        title: '¿Estás seguro?',
        // text: "Al eliminarno perderás los datos",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar'
      }).then((result) => {
        if (result.value) {
          Swal.fire(
            '¡Éxito!',
            'Contacto eliminado con éxito',
            'success'
          )
        }
      })
    }

    openMap(lat, lng, targetModal) {
      console.log(lat);
      console.log(lng);
      lat = lat;
      lng = lng;
      console.log(lat);
      console.log(lng);
      this.modalService.open(targetModal, {
        centered: true,
        backdrop: 'static'
      });    
    }


  
}
