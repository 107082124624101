import { Component, OnInit } from '@angular/core';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-client-objective-contacts',
  templateUrl: './client-objective-contacts.component.html',
  styleUrls: ['./client-objective-contacts.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class ClientObjectiveContactsComponent implements OnInit {

  data: FormGroup;

  files: File[] = [];

  allData = [
    {company: "Nombre Company", fullname: "Jonatan Jaime", service: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {company: "Nombre Company", fullname: "Jonatan Jaime", service: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {company: "Nombre Company", fullname: "Pedro Jaime", service: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {company: "Nombre Company", fullname: "Juan Jaime", service: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {company: "Nombre Company", fullname: "Pepito Jaime", service: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {company: "Nombre Company", fullname: "Fran Jaime", service: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {company: "Nombre Company", fullname: "Jonatan Jaime", service: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {company: "Nombre Company", fullname: "Pedro Jaime", service: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {company: "Nombre Company", fullname: "Juan Jaime", service: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {company: "Nombre Company", fullname: "Pepito Jaime", service: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {company: "Nombre Company", fullname: "Fran Jaime", service: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {company: "Nombre Company", fullname: "Jonatan Jaime", service: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {company: "Nombre Company", fullname: "Pedro Jaime", service: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {company: "Nombre Company", fullname: "Juan Jaime", service: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {company: "Nombre Company", fullname: "Pepito Jaime", service: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
    {company: "Nombre Company", fullname: "Fran Jaime", service: "FrontEnd", telephone: "1234567", phone: "1234567", email: "jonatan.jaime8@gmail.com", skype: "jonatan.jaime"},
  ];
  
  constructor(private fb: FormBuilder, private modalService: NgbModal){
    for(let i=1;i<=10;i++){
      this.allData.push();
    }
  }
  
  ngOnInit() {
    this.data = this.fb.group({
      company: [''],
      service: [''],
      fullname: [''],
      telephone: [''],
      phone: [''],
      email: [''],
      skype: ['']
    });
   }

   // Modal EDIT and NEW
  openModalEdit(targetModal, data) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
   
    this.data.patchValue({
      company: data.company,
      service: data.service,
      fullname: data.fullname,
      telephone: data.telephone,
      phone: data.phone,
      email: data.email,
      skype: data.skype
    });
  }

  openModalNew(targetModal) {
    this.modalService.open(targetModal, {
     centered: true,
     backdrop: 'static'
    });    
  }


  onSubmit() {
    Swal.fire(
      '¡Éxito!',
      'Se ha guardado correctamente',
      'success'
    )
    this.modalService.dismissAll();
    console.log("res:", this.data.getRawValue());
  }


  //Files Dropzone

  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }
   
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  //  DELETE
  delete() {
    Swal.fire({
      title: '¿Estás seguro?',
      // text: "Al eliminarno perderás los datos",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          '¡Éxito!',
          'Punto de control eliminado con éxito',
          'success'
        )
      }
    })
  }


}