import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl  } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-employee-contrac',
  templateUrl: './employee-contrac.component.html',
  styleUrls: ['./employee-contrac.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class EmployeeContracComponent implements OnInit {

  reactivate_user:boolean = false;

  notification_users_boolean:boolean = true;
  notification_users = [
    {email: "emma@gmail.com", name: 'Nombre 1'},
    {email: "joaco@gmail.com", name: 'Nombre 2'},
    {email: "jjaime@gmail.com", name: 'Nombre 3'}
  ];

  reason_for_withdrawal = [
    { date_end: { "year": 2024, "month": 3, "day": 7 }, name: 'Ítem 1', detail: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam molestiae necessitatibus animi quaerat optio voluptatum saepe nisi, debitis recusandae. Eum, obcaecati impedit asperiores molestiae quibusdam eaque repellendus autem error eos?' },
    { date_end: { "year": 2024, "month": 3, "day": 7 }, name: 'Ítem 1', detail: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam molestiae necessitatibus animi quaerat optio voluptatum saepe nisi, debitis recusandae. Eum, obcaecati impedit asperiores molestiae quibusdam eaque repellendus autem error eos?' },
    { date_end: { "year": 2024, "month": 3, "day": 7 }, name: 'Ítem 1', detail: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam molestiae necessitatibus animi quaerat optio voluptatum saepe nisi, debitis recusandae. Eum, obcaecati impedit asperiores molestiae quibusdam eaque repellendus autem error eos?' },
  ];


  data: FormGroup;
  enableTitle = false;
  submitted = false;
  currentDate = new Date();
  files: File[] = [];

  // Modal Alert
  private modalRefAlert;
  data_alert: FormGroup;

  // Modal Modality
  private modalRefModality;
  selectedModality = null;
  data_modality: FormGroup;
  submitModality: string;
  submitModalityEditValue: string;

  items_modality : any[] = [
    { name: 'Agregar nuevo', disabled: true },
    { name: 'Ítem 1'},
    { name: 'Ítem 2'},
    { name: 'Ítem 3'}
  ];
  // end Modal Modality

  // Modal Agreement
  private modalRefAgreement;
  selectedAgreement = null;
  data_agreement: FormGroup;
  submitAgreement: string;
  submitAgreementEditValue: string;

  items_agreement : any[] = [
    { name: 'Agregar nuevo', disabled: true },
    { name: 'Ítem 1'},
    { name: 'Ítem 2'},
    { name: 'Ítem 3'}
  ];
  // end Modal Agreement

  // Modal Category
  private modalRefCategory;
  selectedCategory = null;
  data_category: FormGroup;
  submitCategory: string;
  submitCategoryEditValue: string;

  items_category : any[] = [
    { name: 'Agregar nuevo', disabled: true },
    { name: 'Ítem 1'},
    { name: 'Ítem 2'},
    { name: 'Ítem 3'}
  ];
  // end Modal Category

  // Modal Task
  private modalRefTask;
  selectedTask = null;
  data_task: FormGroup;
  submitTask: string;
  submitTaskEditValue: string;

  items_task : any[] = [
    { name: 'Agregar nuevo', disabled: true },
    { name: 'Ítem 1'},
    { name: 'Ítem 2'},
    { name: 'Ítem 3'}
  ];
  // end Modal Task

  // Modal Syndicate
  private modalRefSyndicate;
  selectedSyndicate = null;
  data_syndicate: FormGroup;
  submitSyndicate: string;
  submitSyndicateEditValue: string;

  items_syndicate : any[] = [
    { name: 'Agregar nuevo', disabled: true },
    { name: 'Ítem 1'},
    { name: 'Ítem 2'},
    { name: 'Ítem 3'}
  ];
  // end Modal Syndicate

  // Modal WorkingDay
  private modalRefWorkingDay;
  selectedWorkingDay = null;
  data_working_day: FormGroup;
  submitWorkingDay: string;
  submitWorkingDayEditValue: string;

  items_working_day : any[] = [
    { name: 'Agregar nuevo', disabled: true },
    { name: 'Ítem 1'},
    { name: 'Ítem 2'},
    { name: 'Ítem 3'}
  ];
  // end Modal WorkingDay

  // Modal Prepaid
  private modalRefPrepaid;
  selectedPrepaid = null;
  data_prepaid: FormGroup;
  submitPrepaid: string;
  submitPrepaidEditValue: string;

  items_prepaid : any[] = [
    { name: 'Agregar nuevo', disabled: true },
    { name: 'Ítem 1'},
    { name: 'Ítem 2'},
    { name: 'Ítem 3'}
  ];
  // end Modal Prepaid

  // Modal Reason
  private modalRefReason;
  selectedReason = null;
  data_reason: FormGroup;
  submitReason: string;
  submitReasonEditValue: string;

  items_reason : any[] = [
    { name: 'Agregar nuevo', disabled: true },
    { name: 'Ítem 1'},
    { name: 'Ítem 2'},
    { name: 'Ítem 3'}
  ];
  // end Modal Reason

  // Modal Reason TYPE
  private modalRefReasonType;
  selectedReasonType = null;
  data_reason_type: FormGroup;
  submitReasonType: string;
  submitReasonTypeEditValue: string;
  // end Modal Reason TYPE


  // DISABLED ON EDIT
  disabled_on_edit:boolean = true;

  constructor(private fb: FormBuilder, private modalService: NgbModal, private cdRef:ChangeDetectorRef){

  }

  ngOnInit() {
    this.initForm();
    this.initFormModality();
    this.initFormAgreement();
    this.initFormReason();
    this.initFormReasonType();
    this.initFormCategory();
    this.initFormTask();
    this.initFormSyndicate();
    this.initFormWorkingDay();
    this.initFormPrepaid();

    this.initFormAlert();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  reactivate() {

    Swal.fire({
      title: '¿Está seguro que quiere reactivar a este empleado?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Reactivar'
    }).then((result) => {
      if (result.value) {
        this.reactivate_user = false;
        Swal.fire(
          '¡Éxito!',
          'Empleado Activado',
          'success'
        )
      }
    })
  }

  initForm() {
    this.data = this.fb.group({
      modality:[null, Validators.required],
      data_begin:[{
        "year": '',
        "month": '',
        "day": ''
      }, Validators.required],
      data_end:[{
        "year": '',
        "month": '',
        "day": ''
      }, Validators.required],
      agreement:[null],
      category:[null],
      task:[null],
      syndicate:[null],
      working_day:[null],
      prepaid:[null],
      hours_per_month:['0', Validators.required],
      minimum_wage:['0.00', Validators.required],
      price_per_hour:['0.00', Validators.required],
      detail:[null],
      retired:[null],
      retired_date:[null],
    });
  }


  get f() { return this.data.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.data.invalid) {
        return;
    }

    Swal.fire(
      '¡Éxito!',
      'Tus cambios se guardaron correctamente',
      'success'
    )
    this.modalService.dismissAll();
    console.log("res:", this.data.getRawValue());

  }

  //Files Dropzone
  onSelect(event) {
    if (this.files.length < 2) {
      console.log(event);
      this.files.push(...event.addedFiles);
    }
  }

  deleteFile(event) {
    Swal.fire({
      title: '¿Está seguro que quiere eliminar este archivo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar'
    }).then((result) => {
      if (result.value) {
        this.files.splice(this.files.indexOf(event), 1);
        Swal.fire(
          '¡Éxito!',
          'Archivo eliminado',
          'success'
        )
      }
    })
  }



  /// FORM MODALITY
  openModalModalityEdit(targetModal, modality) {
    this.modalRefModality = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });

    this.submitModality = 'edit';
    this.submitModalityEditValue = modality.name;

    this.data_modality.patchValue({
      name: modality.name,
    });
  }

  initFormModality() {
    this.data_modality = this.fb.group({
      name:['', Validators.required],
    });
  }

  openModalModalityNew(targetModal) {
    this.initFormModality();
    this.cdRef.detectChanges();

    this.submitModality = 'new';

    this.modalRefModality = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  modalDismissModality() {
    this.modalRefModality.close();
  }

  get fn() { return this.data_modality.controls; }

  onSubmitModality() {

    this.submitted = true;

    if (this.data_modality.invalid) {
        return;
    }

    if(this.submitModality == 'edit') {
      this.items_modality.find(item => item.name == this.submitModalityEditValue).name =  this.data_modality.value.name;
      this.items_modality = [...this.items_modality];
      this.data.get('modality').setValue(this.data_modality.value.name)
    }

    if(this.submitModality == 'new') {
      this.items_modality = [...this.items_modality, this.data_modality.getRawValue()];
      this.data.get('modality').setValue(this.data_modality.value.name)
    }

    this.modalRefModality.close();
    this.cdRef.detectChanges();
  }

  deleteModality(index) {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {

        this.items_modality.splice(index,1);
        this.items_modality = [...this.items_modality];

        this.selectedModality = [];

        this.cdRef.detectChanges();

        console.log(this.items_modality);

        Swal.fire(
          '¡Éxito!',
          'Eliminado correctamente',
          'success'
        )
      }
    })
  }

  /// END FORM MODALITY




  /// FORM Agreement
  openModalAgreementEdit(targetModal, agreement) {
    this.modalRefAgreement = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });

    this.submitAgreement = 'edit';
    this.submitAgreementEditValue = agreement.name;

    this.data_agreement.patchValue({
      name: agreement.name,
    });
  }

  initFormAgreement() {
    this.data_agreement = this.fb.group({
      name:['', Validators.required],
    });
  }

  openModalAgreementNew(targetModal) {
    this.initFormAgreement();
    this.cdRef.detectChanges();

    this.submitAgreement = 'new';

    this.modalRefAgreement = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  modalDismissAgreement() {
    this.modalRefAgreement.close();
  }

  get fn_agreement() { return this.data_agreement.controls; }

  onSubmitAgreement() {

    this.submitted = true;

    if (this.data_agreement.invalid) {
        return;
    }

    if(this.submitAgreement == 'edit') {
      this.items_agreement.find(item => item.name == this.submitAgreementEditValue).name =  this.data_agreement.value.name;
      this.items_agreement = [...this.items_agreement];
      this.data.get('agreement').setValue(this.data_agreement.value.name)
    }

    if(this.submitAgreement == 'new') {
      this.items_agreement = [...this.items_agreement, this.data_agreement.getRawValue()];
      this.data.get('agreement').setValue(this.data_agreement.value.name)
    }

    this.modalRefAgreement.close();
    this.cdRef.detectChanges();
  }

  deleteAgreement(index) {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {

        this.items_agreement.splice(index,1);
        this.items_agreement = [...this.items_agreement];

        this.selectedAgreement = [];

        this.cdRef.detectChanges();

        console.log(this.items_agreement);

        Swal.fire(
          '¡Éxito!',
          'Eliminado correctamente',
          'success'
        )
      }
    })
  }

  /// END FORM Agreement



  /// FORM CATEGORY
  openModalCategoryEdit(targetModal, category) {
    this.modalRefCategory = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });

    this.submitCategory = 'edit';
    this.submitCategoryEditValue = category.name;

    this.data_category.patchValue({
      name: category.name,
    });
  }

  initFormCategory() {
    this.data_category = this.fb.group({
      name:['', Validators.required],
    });
  }

  openModalCategoryNew(targetModal) {
    this.initFormCategory();
    this.cdRef.detectChanges();

    this.submitCategory= 'new';

    this.modalRefCategory = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  modalDismissCategory() {
    this.modalRefCategory.close();
  }

  get fn_category() { return this.data_category.controls; }

  onSubmitCategory() {

    this.submitted = true;

    if (this.data_category.invalid) {
        return;
    }

    if(this.submitCategory == 'edit') {
      this.items_category.find(item => item.name == this.submitCategoryEditValue).name =  this.data_category.value.name;
      this.items_category = [...this.items_category];
      this.data.get('category').setValue(this.data_category.value.name)
    }

    if(this.submitCategory == 'new') {
      this.items_category = [...this.items_category, this.data_category.getRawValue()];
      this.data.get('category').setValue(this.data_category.value.name)
    }

    this.modalRefCategory.close();
    this.cdRef.detectChanges();
  }

  deleteCategory(index) {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {

        this.items_category.splice(index,1);
        this.items_category = [...this.items_category];

        this.selectedCategory = [];

        this.cdRef.detectChanges();

        console.log(this.items_category);

        Swal.fire(
          '¡Éxito!',
          'Eliminado correctamente',
          'success'
        )
      }
    })
  }

  /// END FORM CATEGORY



  /// FORM TASK
  openModalTaskEdit(targetModal, task) {
    this.modalRefTask = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });

    this.submitTask = 'edit';
    this.submitTaskEditValue = task.name;

    this.data_task.patchValue({
      name: task.name,
    });
  }

  initFormTask() {
    this.data_task = this.fb.group({
      name:['', Validators.required],
    });
  }

  openModalTaskNew(targetModal) {
    this.initFormTask();
    this.cdRef.detectChanges();

    this.submitTask= 'new';

    this.modalRefTask = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  modalDismissTask() {
    this.modalRefTask.close();
  }

  get fn_task() { return this.data_task.controls; }

  onSubmitTask() {

    this.submitted = true;

    if (this.data_task.invalid) {
        return;
    }

    if(this.submitTask == 'edit') {
      this.items_task.find(item => item.name == this.submitTaskEditValue).name =  this.data_task.value.name;
      this.items_task = [...this.items_task];
      this.data.get('task').setValue(this.data_task.value.name)
    }

    if(this.submitTask == 'new') {
      this.items_task = [...this.items_task, this.data_task.getRawValue()];
      this.data.get('task').setValue(this.data_task.value.name)
    }

    this.modalRefTask.close();
    this.cdRef.detectChanges();
  }

  deleteTask(index) {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {

        this.items_task.splice(index,1);
        this.items_task = [...this.items_task];

        this.selectedTask = [];

        this.cdRef.detectChanges();

        console.log(this.items_task);

        Swal.fire(
          '¡Éxito!',
          'Eliminado correctamente',
          'success'
        )
      }
    })
  }

  /// END FORM TASK



  /// FORM SYNDICATE
  openModalSyndicateEdit(targetModal, syndicate) {
    this.modalRefSyndicate = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });

    this.submitSyndicate = 'edit';
    this.submitSyndicateEditValue = syndicate.name;

    this.data_syndicate.patchValue({
      name: syndicate.name,
    });
  }

  initFormSyndicate() {
    this.data_syndicate = this.fb.group({
      name:['', Validators.required],
    });
  }

  openModalSyndicateNew(targetModal) {
    this.initFormSyndicate();
    this.cdRef.detectChanges();

    this.submitSyndicate= 'new';

    this.modalRefSyndicate = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  modalDismissSyndicate() {
    this.modalRefSyndicate.close();
  }

  get fn_syndicate() { return this.data_syndicate.controls; }

  onSubmitSyndicate() {

    this.submitted = true;

    if (this.data_syndicate.invalid) {
        return;
    }

    if(this.submitSyndicate == 'edit') {
      this.items_syndicate.find(item => item.name == this.submitSyndicateEditValue).name =  this.data_syndicate.value.name;
      this.items_syndicate = [...this.items_syndicate];
      this.data.get('syndicate').setValue(this.data_syndicate.value.name)
    }

    if(this.submitSyndicate == 'new') {
      this.items_syndicate = [...this.items_syndicate, this.data_syndicate.getRawValue()];
      this.data.get('syndicate').setValue(this.data_syndicate.value.name)
    }

    this.modalRefSyndicate.close();
    this.cdRef.detectChanges();
  }

  deleteSyndicate(index) {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {

        this.items_syndicate.splice(index,1);
        this.items_syndicate = [...this.items_syndicate];

        this.selectedSyndicate = [];

        this.cdRef.detectChanges();

        console.log(this.items_syndicate);

        Swal.fire(
          '¡Éxito!',
          'Eliminado correctamente',
          'success'
        )
      }
    })
  }

  /// END FORM SYNDICATE



  /// FORM WorkingDay
  openModalWorkingDayEdit(targetModal, working_day) {
    this.modalRefWorkingDay = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });

    this.submitWorkingDay = 'edit';
    this.submitWorkingDayEditValue = working_day.name;

    this.data_working_day.patchValue({
      name: working_day.name,
    });
  }

  initFormWorkingDay() {
    this.data_working_day = this.fb.group({
      name:['', Validators.required],
    });
  }

  openModalWorkingDayNew(targetModal) {
    this.initFormWorkingDay();
    this.cdRef.detectChanges();

    this.submitWorkingDay= 'new';

    this.modalRefWorkingDay = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  modalDismissWorkingDay() {
    this.modalRefWorkingDay.close();
  }

  get fn_working_day() { return this.data_working_day.controls; }

  onSubmitWorkingDay() {

    this.submitted = true;

    if (this.data_working_day.invalid) {
        return;
    }

    if(this.submitWorkingDay == 'edit') {
      this.items_working_day.find(item => item.name == this.submitWorkingDayEditValue).name =  this.data_working_day.value.name;
      this.items_working_day = [...this.items_working_day];
      this.data.get('working_day').setValue(this.data_working_day.value.name)
    }

    if(this.submitWorkingDay == 'new') {
      this.items_working_day = [...this.items_working_day, this.data_working_day.getRawValue()];
      this.data.get('working_day').setValue(this.data_working_day.value.name)
    }

    this.modalRefWorkingDay.close();
    this.cdRef.detectChanges();
  }

  deleteWorkingDay(index) {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {

        this.items_working_day.splice(index,1);
        this.items_working_day = [...this.items_working_day];

        this.selectedWorkingDay = [];

        this.cdRef.detectChanges();

        console.log(this.items_working_day);

        Swal.fire(
          '¡Éxito!',
          'Eliminado correctamente',
          'success'
        )
      }
    })
  }

  /// END FORM WorkingDay




  /// FORM Prepaid
  openModalPrepaidEdit(targetModal, prepaid) {
    this.modalRefPrepaid = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });

    this.submitPrepaid = 'edit';
    this.submitPrepaidEditValue = prepaid.name;

    this.data_prepaid.patchValue({
      name: prepaid.name,
    });
  }

  initFormPrepaid() {
    this.data_prepaid = this.fb.group({
      name:['', Validators.required],
    });
  }

  openModalPrepaidNew(targetModal) {
    this.initFormPrepaid();
    this.cdRef.detectChanges();

    this.submitPrepaid= 'new';

    this.modalRefPrepaid = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }

  modalDismissPrepaid() {
    this.modalRefPrepaid.close();
  }

  get fn_prepaid() { return this.data_prepaid.controls; }

  onSubmitPrepaid() {

    this.submitted = true;

    if (this.data_prepaid.invalid) {
        return;
    }

    if(this.submitPrepaid == 'edit') {
      this.items_prepaid.find(item => item.name == this.submitPrepaidEditValue).name =  this.data_prepaid.value.name;
      this.items_prepaid = [...this.items_prepaid];
      this.data.get('prepaid').setValue(this.data_prepaid.value.name)
    }

    if(this.submitPrepaid == 'new') {
      this.items_prepaid = [...this.items_prepaid, this.data_prepaid.getRawValue()];
      this.data.get('prepaid').setValue(this.data_prepaid.value.name)
    }

    this.modalRefPrepaid.close();
    this.cdRef.detectChanges();
  }

  deletePrepaid(index) {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {

        this.items_prepaid.splice(index,1);
        this.items_prepaid = [...this.items_prepaid];

        this.selectedPrepaid = [];

        this.cdRef.detectChanges();

        console.log(this.items_prepaid);

        Swal.fire(
          '¡Éxito!',
          'Eliminado correctamente',
          'success'
        )
      }
    })
  }

  /// END FORM Prepaid






  /// FORM ALERT
  openModalAlertEdit(targetModal, data_alert) {
    this.modalRefAlert = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });

    this.data_alert.patchValue({
      notification_users: data_alert.notification_users,
      notification_previous_alert: data_alert.notification_previous_alert,
      notification_status: data_alert.notification_status
    });
  }

  initFormAlert() {
    this.data_alert = this.fb.group({
      notification_users: [null, Validators.required],
      notification_previous_alert: ['', [Validators.required, Validators.min(1)]],
      notification_status: ['']
    });
  }

  modalDismissAlert() {
    this.modalRefAlert.close();
  }

  get fna() { return this.data_alert.controls; }

  onSubmitAlert() {

    this.submitted = true;

    if (this.data_alert.invalid) {
        return;
    }

    this.notification_users_boolean = false;
    console.log("res:", this.data_alert.getRawValue());
    this.modalRefAlert.close();
    this.cdRef.detectChanges();
  }

    /// FORM REASON
    openModalReasonEdit(targetModal) {
      this.modalRefReason = this.modalService.open(targetModal, {
        centered: true,
        backdrop: 'static'
      });
    }

    initFormReason() {
      this.data_reason = this.fb.group({
        date_end:['', Validators.required],
        name:['', Validators.required],
        description:[''],
      });
    }

    openModalReasonNew(targetModal) {
      this.initFormReason();
      this.cdRef.detectChanges();
      this.modalRefReason = this.modalService.open(targetModal, {
        centered: true,
        backdrop: 'static'
      });
    }

    modalDismissReason() {
      this.modalRefReason.close();
    }

    get fn_reason() { return this.data_reason.controls; }

    onSubmitReason() {

      this.submitted = true;

      if (this.data_reason.invalid) {
          return;
      }

      Swal.fire(
        '¡Éxito!',
        'Personal dado de baja',
        'success'
      )

      this.reactivate_user = true;

      this.modalService.dismissAll();
      console.log("res:", this.data_reason.getRawValue());
      this.selectedReason = [];
    }
    /// END FORM REASON



    /// FORM REASON TYPE
    openModalReasonTypeEdit(targetModal, reason) {
      this.modalRefReasonType = this.modalService.open(targetModal, {
        centered: true,
        backdrop: 'static'
      });

      this.submitReasonType = 'edit';
      this.submitReasonTypeEditValue = reason.name;

      this.data_reason_type.patchValue({
        name: reason.name,
      });
    }

    initFormReasonType() {
      this.data_reason_type = this.fb.group({
        name:['', Validators.required]
      });
    }

    openModalReasonTypeNew(targetModal) {
      this.initFormReason();
      this.cdRef.detectChanges();

      this.submitReasonType = 'new';

      this.modalRefReasonType = this.modalService.open(targetModal, {
        centered: true,
        backdrop: 'static'
      });
    }

    modalDismissReasonType() {
      this.modalRefReason.close();
    }

    get fn_reason_type() { return this.data_reason_type.controls; }

    onSubmitReasonType() {

      this.submitted = true;

      if (this.data_reason_type.invalid) {
          return;
      }

      if(this.submitReasonType == 'edit') {
        this.items_reason.find(item => item.name == this.submitReasonTypeEditValue).name = this.data_reason_type.value.name;
        this.items_reason = [...this.items_reason];
        this.data_reason.get('name').setValue(this.data_reason_type.value.name)
      }

      if(this.submitReason == 'new') {
        this.items_reason = [...this.items_reason, this.data_reason_type.getRawValue()];
        this.data_reason.get('name').setValue(this.data_reason_type.value.name)
      }

      this.modalRefReasonType.close();
      this.cdRef.detectChanges();
    }

    deleteReasonType(index) {
      Swal.fire({
        title: '¿Estás seguro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar'
      }).then((result) => {
        if (result.value) {

          this.items_reason.splice(index,1);
          this.items_reason = [...this.items_reason];

          this.selectedReason = [];

          this.cdRef.detectChanges();

          console.log(this.items_reason);

          Swal.fire(
            '¡Éxito!',
            'Eliminado correctamente',
            'success'
          )
        }
      })
    }

    /// END FORM REASON

}
