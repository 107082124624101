import { Component, OnInit, Inject } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2'

import { ThemeService } from '../../../theme.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})


export class UserProfileComponent implements OnInit {

  themeDark: boolean = true;
  data: FormGroup;

  constructor(
    @Inject(LOCAL_STORAGE)private storage: StorageService, 
    private themeService: ThemeService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.initForm();
  }
  
  initForm() {
    this.data = this.fb.group({
      date_format: [''],
      username: [''],
      password: [''],
      confirm_password: [''],
    }
    );
  }

  get f() { return this.data.controls; }
   
  onSubmit() {
    if (this.data.invalid) {
        return;
    }

    Swal.fire(
      '¡Éxito!',
      'Cambios actualizados correctamente',
      'success'
    )

    console.log("res:", this.data.getRawValue());
  }

  

  themeColor(theme) {
    if (theme == 'light'){
      this.storage.set('theme-dark', 'false');
      this.themeService.toggleLight();
      this.themeDark = false;
    }

    if(theme == 'dark') {
      this.storage.set('theme-dark', 'true');
      this.themeService.toggleDark();
      this.themeDark = true;
    }
  }
}
