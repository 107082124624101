import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2'

import { MapsAPILoader } from '@agm/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class CompanyDetailComponent implements OnInit {

  files: File[] = [];
  filesTwo: File[] = [];
  data: FormGroup;

  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;

  @ViewChild('search',{static:false})
  public searchElementRef: ElementRef;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.initForm();
    this.initMapInput();
    
  }

  initMapInput() {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {

      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  initForm() {
    this.data = this.fb.group({
      address: [''],
      formatted_address: [''],
    }
    );
  }

  onSubmit() {
    if (this.data.invalid) {
        return;
    }

    Swal.fire(
      '¡Éxito!',
      'Cambios actualizados correctamente',
      'success'
    )

    console.log("res:", this.data.getRawValue());
  }


  // Get Current Location Coordinates
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  private getAddressParts(object): Object {
    let address = {};
    const address_components = object.address_components;
    address_components.forEach(element => {
        address[element.types[0]] = element.short_name;
    });
    return address;
}

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {

        console.log(this.getAddressParts(results[0]));

        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          this.data.patchValue({ formatted_address: results[0].formatted_address });
          this.data.patchValue({ address: {
              "PlaceID": results[0].place_id,
              "formatted_address": ""
            }
          });
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
 
    });
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    // this.latitude = $event.coords.lat;
    // this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  onSelect(event) {
    if (this.files.length < 1) {
      console.log(event);
      this.files.push(...event.addedFiles);
    }
  }

  onSelectTwo(event) {
    if (this.filesTwo.length < 1) {
      console.log(event);
      this.filesTwo.push(...event.addedFiles);
    }
  }
   
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  onRemoveTwo(event) {
    console.log(event);
    this.filesTwo.splice(this.filesTwo.indexOf(event), 1);
  }
}
