import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class EmployeesComponent implements OnInit {


  openFilter = false;

  allData = [
    { puesto: "Supervisor", nombre: "Roberto Mendoza", cuil: "12-12222222-1", telefono: "12321321321", celular: "3213213213", ingreso: "21/12/20", status: "Activo", contract: true},
    { puesto: "Vigilante", nombre: "Roberto Mendoza", cuil: "12-12222222-1", telefono: "12321321321", celular: "3213213213", ingreso: "21/12/20", status: "Activo", contract: true},
    { puesto: "Supervisor", nombre: "Roberto Mendoza", cuil: "12-12222222-1", telefono: "12321321321", celular: "3213213213", ingreso: "21/12/20", status: "Baja", contract: false},
    { puesto: "Vigilante", nombre: "Roberto Mendoza", cuil: "12-12222222-1", telefono: "12321321321", celular: "3213213213", ingreso: "21/12/20", status: "Activo", contract: true},
    { puesto: "Supervisor", nombre: "Roberto Mendoza", cuil: "12-12222222-1", telefono: "12321321321", celular: "3213213213", ingreso: "21/12/20", status: "ART", contract: false},
    { puesto: "Vigilante", nombre: "Roberto Mendoza", cuil: "12-12222222-1", telefono: "12321321321", celular: "3213213213", ingreso: "21/12/20", status: "Inactivo", contract: true},
    { puesto: "Supervisor", nombre: "Roberto Mendoza", cuil: "12-12222222-1", telefono: "12321321321", celular: "3213213213", ingreso: "21/12/20", status: "Inactivo", contract: true},
    { puesto: "Vigilante", nombre: "Roberto Mendoza", cuil: "12-12222222-1", telefono: "12321321321", celular: "3213213213", ingreso: "21/12/20", status: "Baja", contract: false},
    { puesto: "Supervisor", nombre: "Roberto Mendoza", cuil: "12-12222222-1", telefono: "12321321321", celular: "3213213213", ingreso: "21/12/20", status: "Activo", contract: false},
    { puesto: "Vigilante", nombre: "Roberto Mendoza", cuil: "12-12222222-1", telefono: "12321321321", celular: "3213213213", ingreso: "21/12/20", status: "ART", contract: true},
    { puesto: "Supervisor", nombre: "Roberto Mendoza", cuil: "12-12222222-1", telefono: "12321321321", celular: "3213213213", ingreso: "21/12/20", status: "Inactivo", contract: true},
  ];

  data: FormGroup;
  submitted = false;
  currentDate = new Date();
  employee = [
    {name: 'Nombre 1', dni: '39111'},
    {name: 'Nombre 2', dni: '30222'},
    {name: 'Nombre 3', dni: '32111'},
    {name: 'Nombre 4', dni: '33444'},
    {name: 'Nombre 5', dni: '44555'}
  ];
  career = [
    {name: 'Formación 1'},
    {name: 'Formación 2'},
    {name: 'Formación 3'},
    {name: 'Formación 4'},
    {name: 'Formación 5'}
  ];
  vehicle = [
    {name: 'Vehículo 1'},
    {name: 'Vehículo 2'},
    {name: 'Vehículo 3'},
    {name: 'Vehículo 4'},
    {name: 'Vehículo 5'}
  ];
  uniform = [
    {name: 'Uniforme 1'},
    {name: 'Uniforme 2'},
    {name: 'Uniforme 3'},
    {name: 'Uniforme 4'},
    {name: 'Uniforme 5'}
  ];
  elements = [
    {name: 'Elementos 1'},
    {name: 'Elementos 2'},
    {name: 'Elementos 3'},
    {name: 'Elementos 4'},
    {name: 'Elementos 5'}
  ];
  qualifications = [
    {name: 'Habilitaciones 1'},
    {name: 'Habilitaciones 2'},
    {name: 'Habilitaciones 3'},
    {name: 'Habilitaciones 4'},
    {name: 'Habilitaciones 5'}
  ];
  documents = [
    {name: 'Documentos 1'},
    {name: 'Documentos 2'},
    {name: 'Documentos 3'},
    {name: 'Documentos 4'},
    {name: 'Documentos 5'}
  ];
  performance_list = [
    {name: '40% o menos'},
    {name: '50% o mas'},
    {name: '60% o mas'},
    {name: '70% o mas'},
    {name: '80% o mas'},
    {name: '90% o mas'},
    {name: '100%'}
  ];
  position = [
    {name: 'Puesto 1'},
    {name: 'Puesto 2'},
    {name: 'Puesto 3'},
    {name: 'Puesto 4'},
    {name: 'Puesto 5'}
  ];
  status = [
    {name: 'Activo'},
    {name: 'Baja'},
    {name: 'ART'},
    {name: 'Suspención'},
    {name: 'Licencia Médica'}
  ];

  province = [
    {name: 'Salta'},
    {name: 'Jujuy'},
    {name: 'Santa Fe'},
    {name: 'Bueno Aires'},
    {name: 'Tucumán'},
  ];

  city = [
    {name: 'Rosario'},
    {name: 'Santa Fe'},
    {name: 'Santa Isabel'},
    {name: 'Venado Tuerto'},
    {name: 'Teodelina'},
    {name: 'Villa Cañas'},
  ];

  contract = [
    {name: 'Con contrato'},
    {name: 'Sin contrato'}
  ];

  constructor(private modalService: NgbModal, private fb: FormBuilder){
    for(let i=1;i<=10;i++){
      this.allData.push();
    }
  }

  ngOnInit() {
    this.initForm();
  }

  //  DELETE
  delete() {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          '¡Éxito!',
          'Eliminado correctamente',
          'success'
        )
      }
    })
  }

  // //  REACTIVE
  // reactive() {
  //   Swal.fire({
  //     title: '¿Esta seguro que desea reactivar al empleado?',
  //     text: "Esto no modificara su situación actual",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Si, reactivarlo'
  //   }).then((result) => {
  //     if (result.value) {
  //       Swal.fire(
  //         '¡Éxito!',
  //         'Reactivado correctamente',
  //         'success'
  //       )
  //     }
  //   })
  // }


  initForm() {
    this.data = this.fb.group({
      employee:[null],
      career:[null],
      vehicle:[null],
      uniform:[null],
      elements:[null],
      qualifications:[null],
      documents:[null],
      performance:[null],
      position:[null],
      status:[null],
      province:[null],
      city:[null],
      contract:[null],
      data_begin:[{
        "year": this.currentDate.getFullYear(),
        "month": this.currentDate.getMonth() + 1,
        "day": this.currentDate.getDate()
      }],
      data_end:[{
        "year": this.currentDate.getFullYear(),
        "month": this.currentDate.getMonth() + 1,
        "day": this.currentDate.getDate()
      }],
    });
  }

  onSubmit() {

    this.submitted = true;

    if (this.data.invalid) {
        return;
    }

    Swal.fire(
      '¡Éxito!',
      'Tus cambios se guardaron correctamente',
      'success'
    )
    console.log("res:", this.data.getRawValue());

  }

  openFilters() {
    if(this.openFilter == true) {
      this.openFilter = false
    }else {
      this.openFilter = true
    }
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.dni.toLocaleLowerCase().indexOf(term) > -1 ||
    item.name.toLocaleLowerCase().indexOf(term) > -1;
  }

}
