import { Component, Renderer2, ViewChild, ElementRef, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-client-objective-checkpoints-task',
  templateUrl: './client-objective-checkpoints-task.component.html',
  styleUrls: ['./client-objective-checkpoints-task.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class ClientObjectiveCheckpointsTaskComponent implements OnInit {

  @ViewChild('task', {static:false}) task: ElementRef;

  constructor(private renderer: Renderer2, private modalService: NgbModal) { }

  ngOnInit() {
  }

  addTask() {
    const task: HTMLDivElement = this.renderer.createElement('task');
    task.innerHTML = `
    <div class="row align-end">
      <div class="col-md-5">
          <div class="wrapper__container__item">
              <span>Título</span>
              <input type="text" name="" >
          </div>
      </div>
      <div class="col-md-5">
          <div class="wrapper__container__item">
              <span>Descripción</span>
              <input type="text" name="">
          </div>
      </div>
      <div class="col-md-2">
          <div class="btns">
            <button class="btn__new small" (click)="save()">Guardar</button>
          </div>
      </div>
  </div>
`
    this.renderer.appendChild(this.task.nativeElement, task)
  }


  // Modal DELETE  
  openModalDelete(targetModal) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });    
  }

  deleteModal() {
    this.modalService.dismissAll();
    console.log("Quiere eliminar");
  }

  cancel() {
    this.modalService.dismissAll();
  }

}
