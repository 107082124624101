import { Component, OnInit } from '@angular/core';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-provider-contact',
  templateUrl: './provider-contact.component.html',
  styleUrls: ['./provider-contact.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class ProviderContactComponent implements OnInit {

  data: FormGroup;

  allData = [
    {name: "Enrique Bustos", tel: "3462619188", tel_oficine: "3462619188", ddi:"12355253531", phone: "3462619188", email: "jonatan.jaime8@gmail.com", comments: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi ab deserunt numquam tenetur perspiciatis iure neque fuga dignissimos quis, placeat repellat alias praesentium, corrupti quia omnis consequuntur libero obcaecati labore!"},
    {name: "Joaquin Jaime", tel: "3462619188", tel_oficine: "3462619188", ddi:"12355253531", phone: "3462619188", email: "jonatan.jaime8@gmail.com", comments: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi ab deserunt numquam tenetur perspiciatis iure neque fuga dignissimos quis, placeat repellat alias praesentium, corrupti quia omnis consequuntur libero obcaecati labore!"},
    {name: "Pedro Lenovo", tel: "3462619188", tel_oficine: "3462619188", ddi:"12355253531", phone: "3462619188", email: "jonatan.jaime8@gmail.com", comments: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi ab deserunt numquam tenetur perspiciatis iure neque fuga dignissimos quis, placeat repellat alias praesentium, corrupti quia omnis consequuntur libero obcaecati labore!"},
    {name: "Uriel Aiwa", tel: "3462619188", tel_oficine: "3462619188", ddi:"12355253531", phone: "3462619188", email: "jonatan.jaime8@gmail.com", comments: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi ab deserunt numquam tenetur perspiciatis iure neque fuga dignissimos quis, placeat repellat alias praesentium, corrupti quia omnis consequuntur libero obcaecati labore!"},
    {name: "Enrique Bustos", tel: "3462619188", tel_oficine: "3462619188", ddi:"12355253531", phone: "3462619188", email: "jonatan.jaime8@gmail.com", comments: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi ab deserunt numquam tenetur perspiciatis iure neque fuga dignissimos quis, placeat repellat alias praesentium, corrupti quia omnis consequuntur libero obcaecati labore!"},
    {name: "Joaquin Jaime", tel: "3462619188", tel_oficine: "3462619188", ddi:"12355253531", phone: "3462619188", email: "jonatan.jaime8@gmail.com", comments: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi ab deserunt numquam tenetur perspiciatis iure neque fuga dignissimos quis, placeat repellat alias praesentium, corrupti quia omnis consequuntur libero obcaecati labore!"},
    {name: "Pedro Lenovo", tel: "3462619188", tel_oficine: "3462619188", ddi:"12355253531", phone: "3462619188", email: "jonatan.jaime8@gmail.com", comments: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi ab deserunt numquam tenetur perspiciatis iure neque fuga dignissimos quis, placeat repellat alias praesentium, corrupti quia omnis consequuntur libero obcaecati labore!"},
    {name: "Uriel Aiwa", tel: "3462619188", tel_oficine: "3462619188", ddi:"12355253531", phone: "3462619188", email: "jonatan.jaime8@gmail.com", comments: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi ab deserunt numquam tenetur perspiciatis iure neque fuga dignissimos quis, placeat repellat alias praesentium, corrupti quia omnis consequuntur libero obcaecati labore!"},
    {name: "Enrique Bustos", tel: "3462619188", tel_oficine: "3462619188", ddi:"12355253531", phone: "3462619188", email: "jonatan.jaime8@gmail.com", comments: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi ab deserunt numquam tenetur perspiciatis iure neque fuga dignissimos quis, placeat repellat alias praesentium, corrupti quia omnis consequuntur libero obcaecati labore!"},
    {name: "Joaquin Jaime", tel: "3462619188", tel_oficine: "3462619188", ddi:"12355253531", phone: "3462619188", email: "jonatan.jaime8@gmail.com", comments: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi ab deserunt numquam tenetur perspiciatis iure neque fuga dignissimos quis, placeat repellat alias praesentium, corrupti quia omnis consequuntur libero obcaecati labore!"},
    {name: "Pedro Lenovo", tel: "3462619188", tel_oficine: "3462619188", ddi:"12355253531", phone: "3462619188", email: "jonatan.jaime8@gmail.com", comments: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi ab deserunt numquam tenetur perspiciatis iure neque fuga dignissimos quis, placeat repellat alias praesentium, corrupti quia omnis consequuntur libero obcaecati labore!"},
    {name: "Uriel Aiwa", tel: "3462619188", tel_oficine: "3462619188", ddi:"12355253531", phone: "3462619188", email: "jonatan.jaime8@gmail.com", comments: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi ab deserunt numquam tenetur perspiciatis iure neque fuga dignissimos quis, placeat repellat alias praesentium, corrupti quia omnis consequuntur libero obcaecati labore!"},
  
  ];
  
  constructor(private fb: FormBuilder, private modalService: NgbModal){
    for(let i=1;i<=10;i++){
      this.allData.push();
    }
  }
  
  ngOnInit() {
    this.data = this.fb.group({
      name: [''],
      phone: [''],
      email: [''],
      tel: [''],
      tel_oficine: [''],
      ddi: [''],
      comments: ['']
    });
   }

   // Modal EDIT and NEW
  openModalEdit(targetModal, data) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
   
    this.data.patchValue({
      name: data.name,
      phone: data.phone,
      email: data.email,
      tel: data.tel,
      tel_oficine: data.tel_oficine,
      ddi: data.ddi,
      comments: data.comments
    });
  }

  openModalNew(targetModal) {
    this.modalService.open(targetModal, {
     centered: true,
     backdrop: 'static'
    });    
    this.data.patchValue({
      name: '',
      phone: '',
      email: '',
      tel: '',
      tel_oficine: '',
      ddi: '',
      comments: ''
    });
  }

   
  onSubmit() {
    this.modalService.dismissAll();
    console.log("res:", this.data.getRawValue());
    Swal.fire(
      '¡Éxito!',
      'Se ha guardado correctamente',
      'success'
    )    
  }


// DELETE
delete(userName) {
  Swal.fire({
    title: '¿Esta seguro que desea eliminar este contacto?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, eliminar'
  }).then((result) => {
    if (result.value) {
      Swal.fire(
        '¡Éxito!',
        userName + ' eliminado correctamente',
        'success'
      )
    }
  })
}
}

