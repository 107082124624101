import { Component, OnInit, Inject } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-billing-information',
  templateUrl: './user-billing-information.component.html',
  styleUrls: ['./user-billing-information.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class UserBillingInformationComponent implements OnInit {

  data: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.initForm();
  }
  
  initForm() {
    this.data = this.fb.group({
      company_name:['', [Validators.required, Validators.maxLength(60)]],
      company_cuit:['',[Validators.required, Validators.maxLength(60)]],
      address: [''],
    }
    );
  }

  get f() { return this.data.controls; }
   
  onSubmit() {
    if (this.data.invalid) {
        return;
    }

    Swal.fire(
      '¡Éxito!',
      'Cambios actualizados correctamente',
      'success'
    )

    console.log("res:", this.data.getRawValue());
  }

}
