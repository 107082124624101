import {NgModule} from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';

// Pages - Dashboard
import { DashboardComponent } from './pages/dashboard/dashboard.component';

// Pages - Company
import { CompanyComponent } from './pages/company/company.component';
import { CompanyDetailComponent } from './pages/company/company-detail/company-detail.component';
import { CompanyBanksComponent } from './pages/company/company-banks/company-banks.component';
import { CompanyPenaltyComponent } from './pages/company/company-penalty/company-penalty.component';
import { CompanyBranchesComponent } from './pages/company/company-branches/company-branches.component';
import { CompanyInsuranceComponent } from './pages/company/company-insurance/company-insurance.component';
import { CompanyMedicineComponent } from './pages/company/company-medicine/company-medicine.component';
import { CompanyQualificationsComponent } from './pages/company/company-qualifications/company-qualifications.component';
import { CompanyDocumentsComponent } from './pages/company/company-documents/company-documents.component';
import { CompanyAbsenceComponent } from './pages/company/company-absence/company-absence.component';
import { CompanyConfigurationComponent } from './pages/company/company-configuration/company-configuration.component';


// Pages - Client
import { ClientsComponent } from './pages/client/clients/clients.component';
import { ClientComponent } from './pages/client/client.component';

import { ClientContracComponent } from './pages/client/client-contrac/client-contrac.component';
import { ClientHistoricalComponent } from './pages/client/client-historical/client-historical.component';

import { ClientBanksComponent } from './pages/client/client-banks/client-banks.component';
import { ClientDetailComponent } from './pages/client/client-detail/client-detail.component';
import { ClientDocumentsComponent } from './pages/client/client-documents/client-documents.component';
import { ClientQualificationsComponent } from './pages/client/client-qualifications/client-qualifications.component';
import { ClientFinancesComponent } from './pages/client/client-finances/client-finances.component';
import { ClientContactsComponent } from './pages/client/client-contacts/client-contacts.component';
import { ClientObjectivesComponent } from './pages/client/client-objectives/client-objectives.component';

import { ClientObjectiveComponent } from './pages/client/client-objective/client-objective.component';
import { ClientObjectiveDetailComponent } from './pages/client/client-objective-detail/client-objective-detail.component';
import { ClientObjectiveAreasComponent } from './pages/client/client-objective-areas/client-objective-areas.component';
import { ClientObjectiveCheckpointsComponent } from './pages/client/client-objective-checkpoints/client-objective-checkpoints.component';
import { ClientObjectiveCheckpointsTaskComponent } from './pages/client/client-objective-checkpoints-task/client-objective-checkpoints-task.component';
import { ClientObjectiveCheckpointsRoundManagementComponent } from './pages/client/client-objective-checkpoints-round-management/client-objective-checkpoints-round-management.component';
import { ClientObjectiveCheckpointsRoundManagementEditComponent } from './pages/client/client-objective-checkpoints-round-management-edit/client-objective-checkpoints-round-management-edit.component';

import { ClientObjectiveRoundsComponent } from './pages/client/client-objective-rounds/client-objective-rounds.component';
import { ClientObjectiveStaffComponent } from './pages/client/client-objective-staff/client-objective-staff.component';
import { ClientObjectiveScheduleComponent } from './pages/client/client-objective-schedule/client-objective-schedule.component';
import { ClientObjectiveElementsComponent } from './pages/client/client-objective-elements/client-objective-elements.component';
import { ClientObjectiveContactsComponent } from './pages/client/client-objective-contacts/client-objective-contacts.component';

// Pages - Employee
import { EmployeeComponent } from './pages/employee/employee.component';
import { EmployeesComponent } from './pages/employee/employees/employees.component';
import { EmployeeDetailComponent } from './pages/employee/employee-detail/employee-detail.component';
import { EmployeeDocumentsComponent } from './pages/employee/employee-documents/employee-documents.component';
import { EmployeeQualificationsComponent } from './pages/employee/employee-qualifications/employee-qualifications.component';
import { EmployeeVehicleComponent } from './pages/employee/employee-vehicle/employee-vehicle.component';
import { EmployeeCareerComponent } from './pages/employee/employee-career/employee-career.component';
import { EmployeeInventoryComponent } from './pages/employee/employee-inventory/employee-inventory.component';
import { EmployeeHistoryComponent } from './pages/employee/employee-history/employee-history.component';
import { EmployeeHrComponent } from './pages/employee/employee-hr/employee-hr.component';
import { EmployeeMedicalRecordComponent } from './pages/employee/employee-medical-record/employee-medical-record.component';
import { EmployeePerformanceComponent } from './pages/employee/employee-performance/employee-performance.component';
import { EmployeeBanksComponent } from './pages/employee/employee-banks/employee-banks.component';
import { EmployeeAbsenceComponent } from './pages/employee/employee-absence/employee-absence.component';
import { EmployeePenaltyComponent } from './pages/employee/employee-penalty/employee-penalty.component';
import { EmployeeContracComponent } from './pages/employee/employee-contrac/employee-contract.component';
import { EmployeeHistoricalComponent } from './pages/employee/employee-historical/employee-historical.component';


// Pages - Configuration
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { ConfigurationSegurityComponent } from './pages/configuration/configuration-segurity/configuration-segurity.component';
import { ConfigurationRoleComponent } from './pages/configuration/configuration-role/configuration-role.component';
import { ConfigurationRolePermissionsComponent } from './pages/configuration/configuration-role-permissions/configuration-role-permissions.component';
import { ConfigurationUserPermissionsComponent } from './pages/configuration/configuration-user-permissions/configuration-user-permissions.component';


// Pages - Providers
import { ProvidersComponent } from './pages/providers/providers.component';
import { ProviderComponent } from './pages/providers/provider/provider.component';
import { ProviderBanksComponent } from './pages/providers/provider-banks/provider-banks.component';
import { ProviderDetailComponent } from './pages/providers/provider-detail/provider-detail.component';
import { ProviderContactComponent } from './pages/providers/provider-contact/provider-contact.component';
import { ProviderShopComponent } from './pages/providers/provider-shop/provider-shop.component';
import { ProviderRefundComponent } from './pages/providers/provider-refund/provider-refund.component';
import { ProviderCostsComponent } from './pages/providers/provider-costs/provider-costs.component';
import { ProviderInsuranceComponent } from './pages/providers/provider-insurance/provider-insurance.component';

// Pages - User
import { UserComponent } from './pages/user/user.component';
import { UserProfileComponent } from './pages/user/user-profile/user-profile.component';
import { UserPaymentMethodsComponent } from './pages/user/user-payment-methods/user-payment-methods.component';
import { UserBillingHistoryComponent } from './pages/user/user-billing-history/user-billing-history.component';
import { UserBillingInformationComponent } from './pages/user/user-billing-information/user-billing-information.component';
import { UserNotificationsComponent } from './pages/user/user-notifications/user-notifications.component';


const appRoutes: Routes = [
    { path: 'dashboard', component: DashboardComponent },

    { path: 'company',
      component: CompanyComponent,
      children: [
        { path: 'detail', component: CompanyDetailComponent },
        { path: 'penalty', component: CompanyPenaltyComponent },
        { path: 'banks', component: CompanyBanksComponent },
        { path: 'branches', component: CompanyBranchesComponent },
        { path: 'insurance', component: CompanyInsuranceComponent },
        { path: 'medicine', component: CompanyMedicineComponent },
        { path: 'qualifications', component: CompanyQualificationsComponent },
        { path: 'documents', component: CompanyDocumentsComponent },
        { path: 'absence', component: CompanyAbsenceComponent },
        { path: 'configuration', component: CompanyConfigurationComponent },
      ]
    },

    { path: 'clients', component: ClientsComponent },

    { path: 'client',
      component: ClientComponent,
      children: [
        { path: 'detail', component: ClientDetailComponent },
        { path: 'contract', component: ClientContracComponent },
        { path: 'historical', component: ClientHistoricalComponent },
        { path: 'banks', component: ClientBanksComponent },
        { path: 'objectives', component: ClientObjectivesComponent },
        { path: 'objective',
          component: ClientObjectiveComponent,
          children: [
            { path: 'detail', component: ClientObjectiveDetailComponent },
            { path: 'areas', component: ClientObjectiveAreasComponent },
            { path: 'checkpoints', component: ClientObjectiveCheckpointsComponent },
            { path: 'checkpoints/task', component: ClientObjectiveCheckpointsTaskComponent },
            { path: 'checkpoints/round-management', component: ClientObjectiveCheckpointsRoundManagementComponent },
            { path: 'checkpoints/round-management/edit', component: ClientObjectiveCheckpointsRoundManagementEditComponent },
            { path: 'rounds', component: ClientObjectiveRoundsComponent },
            { path: 'staff', component: ClientObjectiveStaffComponent },
            { path: 'schedule', component: ClientObjectiveScheduleComponent },
            { path: 'elements', component: ClientObjectiveElementsComponent },
            { path: 'contacts', component: ClientObjectiveContactsComponent },
          ]
        },
        { path: 'documents', component: ClientDocumentsComponent },
        { path: 'qualifications', component: ClientQualificationsComponent },
        { path: 'finances', component: ClientFinancesComponent },
        { path: 'contacts', component: ClientContactsComponent },
      ]
    },

    { path: 'employees', component: EmployeesComponent },

    { path: 'employee',
      component: EmployeeComponent,
      children: [
        { path: 'absence', component: EmployeeAbsenceComponent },
        { path: 'banks', component: EmployeeBanksComponent },
        { path: 'detail', component: EmployeeDetailComponent },
        { path: 'documents', component: EmployeeDocumentsComponent },
        { path: 'qualifications', component: EmployeeQualificationsComponent },
        { path: 'vehicle', component: EmployeeVehicleComponent },
        { path: 'career', component: EmployeeCareerComponent },
        { path: 'inventory', component: EmployeeInventoryComponent },
        { path: 'history', component: EmployeeHistoryComponent },
        { path: 'human-resources', component: EmployeeHrComponent },
        { path: 'medial-record', component: EmployeeMedicalRecordComponent },
        { path: 'performance', component: EmployeePerformanceComponent },
        { path: 'penalty', component: EmployeePenaltyComponent },
        { path: 'contrac', component: EmployeeContracComponent },
        { path: 'historical', component: EmployeeHistoricalComponent }
      ]
    },

    { path: 'configuration',
      component: ConfigurationComponent,
      children: [
        { path: 'segurity', component: ConfigurationSegurityComponent },
        { path: 'role', component: ConfigurationRoleComponent },
        { path: 'role/permissions', component: ConfigurationRolePermissionsComponent },
        { path: 'user/permissions', component: ConfigurationUserPermissionsComponent },
      ]
    },

    { path: 'user',
      component: UserComponent,
      children: [
        { path: 'profile', component: UserProfileComponent },
        { path: 'payment-methods', component: UserPaymentMethodsComponent },
        { path: 'billing-history', component: UserBillingHistoryComponent },
        { path: 'billing-information', component: UserBillingInformationComponent },
        { path: 'notifications', component: UserNotificationsComponent },
      ]
    },

    { path: 'providers', component: ProvidersComponent },

    { path: 'provider', component: ProviderComponent,
      children: [
        { path: 'detail', component: ProviderDetailComponent },
        { path: 'banks', component: ProviderBanksComponent },
        { path: 'contact', component: ProviderContactComponent },
        { path: 'shop', component: ProviderShopComponent },
        { path: 'refund', component: ProviderRefundComponent },
        { path: 'costs', component:  ProviderCostsComponent},
        { path: 'insurance', component:  ProviderInsuranceComponent},
      ]
    },

    { path: '**', component: PageNotFoundComponent }
  ];

  NgModule({
    imports: [
      RouterModule.forRoot(appRoutes)
    ],
    exports: [RouterModule]
  })
  export const APP_ROUTES = RouterModule.forRoot( appRoutes, { useHash: true } );
