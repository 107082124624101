import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-company-branches',
  templateUrl: './company-branches.component.html',
  styleUrls: ['./company-branches.component.scss'],
  animations: [
    trigger('animation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(1000 )
      ]),

      transition(':leave',
        animate(1000, style({opacity: 0})))
    ])
  ]
})
export class CompanyBranchesComponent implements OnInit {

  data: FormGroup;

  allData = [
    {direction: "Av. Libertador Gral San Martin", city: "Rosario", state: "Santa Fe", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "San Martin 123", city: "Mendoza", state: "Mendoza", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "Ingeniero Ramón Cacho Rodolfo", city: "Río Cuarto", state: "Córdoba", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "Cordón del plata lorem", city: "Río Cuarto", state: "Córdoba", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "Av. Libertador Gral San Martin", city: "Rosario", state: "Santa Fe", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "San Martin 123", city: "Mendoza", state: "Mendoza", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "Ingeniero Ramón Cacho Rodolfo", city: "Río Cuarto", state: "Córdoba", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "Cordón del plata lorem", city: "Río Cuarto", state: "Córdoba", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "Av. Libertador Gral San Martin", city: "Rosario", state: "Santa Fe", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "San Martin 123", city: "Mendoza", state: "Mendoza", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "Ingeniero Ramón Cacho Rodolfo", city: "Río Cuarto", state: "Córdoba", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "Cordón del plata lorem", city: "Río Cuarto", state: "Córdoba", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "Av. Libertador Gral San Martin", city: "Rosario", state: "Santa Fe", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "San Martin 123", city: "Mendoza", state: "Mendoza", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "Ingeniero Ramón Cacho Rodolfo", city: "Río Cuarto", state: "Córdoba", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "Cordón del plata lorem", city: "Río Cuarto", state: "Córdoba", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "Av. Libertador Gral San Martin", city: "Rosario", state: "Santa Fe", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "San Martin 123", city: "Mendoza", state: "Mendoza", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "Ingeniero Ramón Cacho Rodolfo", city: "Río Cuarto", state: "Córdoba", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "Cordón del plata lorem", city: "Río Cuarto", state: "Córdoba", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "Av. Libertador Gral San Martin", city: "Rosario", state: "Santa Fe", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "San Martin 123", city: "Mendoza", state: "Mendoza", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "Ingeniero Ramón Cacho Rodolfo", city: "Río Cuarto", state: "Córdoba", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
    {direction: "Cordón del plata lorem", city: "Río Cuarto", state: "Córdoba", phone: "3462619188", email: "jonatan.jaime8@gmail.com", postal_code: "2000"},
  ];

  // @ViewChild('search',{static:false})
  // public searchElementRef: ElementRef;

  @ViewChild('search', {static: false}) search: ElementRef;

  private geoCoder;
  
  constructor(
    private fb: FormBuilder, 
    private modalService: NgbModal,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private changeDetector: ChangeDetectorRef
    ){
    for(let i=1;i<=10;i++){
      this.allData.push();
    }
  }
  
  ngOnInit() {
    this.data = this.fb.group({
      direction: [''],
      city: [''],
      state: [''],
      phone: [''],
      email: [''],
      postal_code: ['']
    });
   }

   inputGMaps( $event ) {
      console.log($event);
   }


  //  addressGMaps() {
  //   this.changeDetector.detectChanges();
  //    //load Places Autocomplete
  //   this.mapsAPILoader.load().then(() => {
  //     this.geoCoder = new google.maps.Geocoder;
  //     let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
  //       types: ["address"]
  //     });
  //     autocomplete.addListener("place_changed", () => {
  //       this.ngZone.run(() => {
  //         //get the place result
  //         let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          
  //         //verify result
  //         if (place.geometry === undefined || place.geometry === null) {
  //           return;
  //         }

  //         //set latitude, longitude and zoom
  //         // this.latitude = place.geometry.location.lat();
  //         // this.longitude = place.geometry.location.lng();
  //         // this.zoom = 12;
  //       });
  //     });
  //   });
  //  }

   // Modal EDIT and NEW
  openModalEdit(targetModal, data) {
    
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
   
    this.data.patchValue({
      direction: data.direction,
      city: data.city,
      state: data.state,
      phone: data.phone,
      email: data.email,
      postal_code: data.postal_code
    });
  }

  openModalNew(targetModal) {
    // this.addressGMaps();
    this.modalService.open(targetModal, {
     centered: true,
     backdrop: 'static'
    });    
  }

   
  onSubmit() {
    Swal.fire(
      '¡Éxito!',
      'Tus cambios se guardaron correctamente',
      'success'
    )
    this.modalService.dismissAll();
    console.log("res:", this.data.getRawValue());
  }


  // DELETE
  delete() {
    Swal.fire({
      title: '¿Estás seguro?',
      // text: "Al eliminarno perderás los datos",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          '¡Éxito!',
          'Sucursal eliminada con éxito',
          'success'
        )
      }
    })
  }

}
